import { Button } from 'react-bootstrap';
import Modal from 'react-bootstrap/Modal';
import { FaExclamationCircle } from "react-icons/fa";
import './style.css';
import { useState } from 'react';

const MsgDialogObservaciones = (props: {Show: boolean, Title: string, Icon: string, Message: string, 
                  BtnOkName: string, BtnNokName: string, 
                  HandlerClickOk: any, HanlerdClickNok: any}) => {
           
    const [obs, setObs] = useState("");

    const handler = (evnt: any) =>{

        setObs(evnt.target.value);

    }

 return (
      <div>
        <Modal show={props.Show} centered={true} >
            <Modal.Header  >
                <Modal.Title className='h3 text-center'>
                    {                        
                        props.Title
                    }
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className='d-flex flex-column gap-4 justify-content-center align-middle'>
                    <div className='d-flex align-items-center '><span className=' h5'>{props.Message}</span></div>
                    <input type="text" className='form-control' onChange={handler} />
                </div>
            </Modal.Body>                
            <Modal.Footer className=''>
                {
                    (props.BtnOkName) 
                        ? 
                        <Button type="button" className="btn btn-success btnColorOk" onClick={(evnt: any)=>props.HandlerClickOk(obs.trim())} disabled={obs.trim() === ""}>
                            {props.BtnOkName}
                        </Button>
                        : <div></div>
                }      
                {
                    (props.BtnNokName) 
                        ? 
                        <Button type="button" className="btn btn-danger btnColorNOk" onClick={props.HanlerdClickNok}>
                            {props.BtnNokName}
                        </Button>
                        : <div></div>
                }                                      
            </Modal.Footer>
        </Modal>
      </div>
  );
}

export default MsgDialogObservaciones;