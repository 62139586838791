import { Button } from 'react-bootstrap';
import Modal from 'react-bootstrap/Modal';
import './style.css';
import { useState } from 'react';

const ModalObservacion= (props: {Show: boolean, Title: string, Icon: string, Descripcion: string, 
                  BtnOkName: string, BtnNokName: string, 
                  HandlerClickOk: any, HanlerdClickNok: any}) => {

    const [desc, setDesc] = useState("");
    const [alert, setAlert] = useState(false);
  

 return (
      <div>
        <Modal show={props.Show} centered={true} size="lg" >
            <Modal.Header  >
                <Modal.Title className='h3 text-center'>
                    {                        
                        props.Title
                    }
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className='d-flex flex-column'>
                    <input type="text" id="desc" className="ms-2  form-control" value={props.Descripcion} disabled />
                </div>
            </Modal.Body>                
            <Modal.Footer className=''>
                {
                    (props.BtnOkName) 
                        ? 
                        <Button type="button" className="btn btn-success btnColorOk" onClick={props.HandlerClickOk}>
                            {props.BtnOkName}
                        </Button>
                        : <div></div>
                }      
                {
                    (props.BtnNokName) 
                        ? 
                        <Button type="button" className="btn btn-danger btnColorNOk" onClick={props.HanlerdClickNok}>
                            {props.BtnNokName}
                        </Button>
                        : <div></div>
                }                                      
            </Modal.Footer>
        </Modal>
      </div>
  );
}

export default ModalObservacion;