import  'bootstrap/dist/css/bootstrap.min.css' ;
import AperturaReconteoPage from './pages/Reconteo/AperturaReconteoPage';
import AvanceCierreMuestrasPage from './pages/Muestras/AvanceCierreMuestrasPage';
import AvanceCierreReconteoPage from './pages/Reconteo/AvanceCierreReconteoPage';
import AvanceInventarioPage from './pages/Consultas/AvanceInventarioPage';
import DashboardPage from './pages/DashboardPage';
import DescargaMasivaPage from './pages/Consultas/DescargaMasivaPage';
import GestionInventarioPage from './pages/Inventario/GestionInventarioPage';
import GestionSubInventarioPage from './pages/Inventario/GestionSubInventarioPage';
import InformeSaldosPage from './pages/Consultas/InformeSaldosPageU';
import PageLogin from './pages/Login';
import ReporteInventarioPage from './pages/Consultas/ReporteInventarioPage';
import TratamientoEtiquetaPage from './pages/Inventario/TratamientoEtiquetaPage';
import EtiquetaSinRefPage from './pages/Impresiones/EtiquetaSinRefPage';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import PeriodoMuestrasPageII from './pages/Muestras/PeriodoMuestrasPageII';
import LandingPage from './pages/LandingPage';
import ClasificacionDiferencias from './pages/ClasificacionPage/DiferenciasPage';
import SaldosMuestrasPage from './pages/Muestras/SaldosMuestrasPage';
import DocumentosPage from './pages/Inventario/DocumentosPage';
import KardexInicialPage from './pages/Auditoria/KardexInicialPage';
import KardexFinalPage from './pages/Auditoria/KardexFinalPage';
import RollforwardPage from './pages/Auditoria/RollforwardPage';
import RespaldoPage from './pages/Auditoria/Respaldo';
import ClasificacionMuestras from './pages/ClasificacionPage/Muestras';
import HoraCorte from './pages/Mantenedor/HoraCorte';
import GestionMuestras from './pages/Muestras/GestionMuestras';
import DescargaMasivaMuestrasPage from './pages/Muestras/DescargaMasivaMuestrasPage';
import SolicitudMermasPage from './pages/Mermas/SolicitudMermasPage/index';
import ConsultaEditaMermasPage from './pages/Mermas/ConsultaEditaMermasPage';
import GestionMermasPage from './pages/Mermas/GestionMermas';

export function App() {

  return (
    <div>
      <BrowserRouter>      
        <Routes>
          <Route path="/mermas/gestion" element={<GestionMermasPage />}/>    
          <Route path="/mermas/editar" element={<ConsultaEditaMermasPage />}/>            
          <Route path="/mermas/solicitud" element={<SolicitudMermasPage />}/>   
          <Route path="/muestras/descargamasiva" element={<DescargaMasivaMuestrasPage />}/>            
          <Route path="/muestras/gestion" element={<GestionMuestras />}/>  
          <Route path="/mantenedor/horacorte" element={<HoraCorte />}/>   
          <Route path="/auditoria/detalle" element={<RespaldoPage />}/>               
          <Route path="/auditoria/rollforward" element={<RollforwardPage />}/>           
          <Route path="/auditoria/kardexfinal" element={<KardexFinalPage />}/> 
          <Route path="/auditoria/kardexinicial" element={<KardexInicialPage />}/> 
          <Route path="/clasificacion/diferencias" element={<ClasificacionDiferencias />}/>   
          <Route path="/clasificacion/muestras" element={<ClasificacionMuestras />}/>             
          <Route path="/muestras/saldos" element={<SaldosMuestrasPage />}/>                   
          <Route path="/muestras/avance" element={<AvanceCierreMuestrasPage />}/> 
          <Route path="/muestras/periodo" element={<PeriodoMuestrasPageII />}/> 
          <Route path="/reconteo/avance" element={<AvanceCierreReconteoPage />}/> 
          <Route path="/reconteo/apertura" element={<AperturaReconteoPage /> }/>
          <Route path="/consultas/descargamasiva" element={<DescargaMasivaPage /> }/>
          <Route path="/consultas/informesaldos" element={<InformeSaldosPage /> }/>
          <Route path="/consultas/reporteinventario" element={<ReporteInventarioPage />}/>
          <Route path="/consultas/avanceinventario" element={<AvanceInventarioPage /> }/>
          <Route path="/inventario/tratamiento" element={<TratamientoEtiquetaPage/> }/>
          <Route path="/inventario/documentos" element={<DocumentosPage /> }/>          
          <Route path="/inventario/gestionsubinventario" element={<GestionSubInventarioPage /> }/>
          <Route path="/inventario/gestioninventario" element={<GestionInventarioPage /> }/>
          <Route path="/impresiones/etiquetas" element={<EtiquetaSinRefPage /> }/>
          <Route path="/dashboard" element={<DashboardPage /> }/>
          <Route path="/landingpage" element={<LandingPage />}/>          
          <Route path="/" element={<PageLogin />}/>
        </Routes>              
      </BrowserRouter>
    </div>
  );
}

export default App;
