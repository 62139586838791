import { useEffect, useState } from "react";
import { Accordion, Button, Container, } from "react-bootstrap";
import { FaCalendarAlt, FaTruck, } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import SelectAlmacenes from "../../../components/Almacenes";
import SelectCentro from "../../../components/Centros";
import LocalMsgModalSpinner from "../../../components/LocalMsgModalSpinner";
import * as env from '../../../env';
import { httpApiGetText, httpApiPostText } from "../../../lib";
import DataTable from "react-data-table-component";
import { formatDate, formatTime } from "../../../util";
import MsgDialog from "../../../components/MsgDialog";
import BarraMenuHz from "../../../components/BarraMenoHz";
import { SetEntornoEmp } from "../../../redux/store/Actions";
import * as XLSX from "xlsx";
import MsgMessageDialog from "../../../components/MsgMessageDialog";
import ModalDescripcion from "./ModalDescripcion";
  
const SolicitudMermasPage = () =>{

    let emp: any = useSelector((state: any) => state.emp);  
    const [hbltBtnAbrir, setHbltBtnAbrir] = useState(true); 
    let [formData, setFormData] = useState({ Pais: "CL", Anno: "", Mes: "",  Centro: "", Almacen: "", Centro_Costo: "", Descripcion: ""});
    const [sHCarga, setSHCarga] = useState(false);   
    let [showMsgApiResponse, setShowMsgApiResponse] = useState(false); 
    const [MsgApiResponse, setMsgApiResponse] = useState<string[]>([]); 
    const [showAdvertencia, setShowAdvertencia] = useState(false);       
    const [showResponseOk, setShowResponseOk] = useState(false);       
    let [msgAdvertencia, setMsgAdvertencia] = useState("");       
    const dispatch = useDispatch();    
    let [fileName, setFilename] = useState<any>();     
    let [codigos, setCodigos] = useState<any>([]); 
    let [DetalleMerma, setDetalleMerma] = useState<any>([]);        
    const [showDesc, setShowDesc] = useState(false); 
    const [solicitud, setSolicitud] = useState("");      

    let columnas = [
        { name: " Nro. Solicitud", selector: (row:any) => row.NRO_SOLICITUD, sortable: true,  width:"150px", right: true},
        { name: "Sku", selector: (row:any) => row.INV_ITEM_ID, sortable: true,  width:"120px"},
        { name: "Cantidad", selector: (row:any) => row.CANTIDAD, sortable: true,  width:"120px", right: true},
        { name: "Fecha", selector: (row:any) => row.FECHA, sortable: true,  width:"180px", wrap:true},        
        { name: "Usuario", selector: (row:any) => row.USUARIO, sortable: true, width:"120px"}                          
    ];
    
    const pagOptions = {
        rowsPerPageText: "Filas por páginas",
        rangeSeparatorText: "de",
        selectAllRowsItem: true,
        selectAllRowsItemText: "Todos"
    };

    const ValidaBtnAbrir = () => {     
        if ((formData.Anno !== "") &&
            (formData.Centro !== "") && 
            (formData.Almacen !== "") &&             
            (formData.Centro_Costo !== "")){     
                setHbltBtnAbrir(false); 
        }else{
            setHbltBtnAbrir(true); 
        }
    }

    const hndlrPeriodo = async (evnt: any) =>{
        const f: Date = evnt.target.value; 
        const yy = f.toString().split('-')[0];
        const mm = f.toString().split('-')[1];

        emp = {...emp, periodoDefault: `${yy}-${mm}`};
        dispatch(SetEntornoEmp({...emp}));  

        sessionStorage.setItem("entorno", JSON.stringify(emp));   
                
        formData = {...formData, Anno: yy, Mes: mm};
        setFormData(formData);
        ValidaBtnAbrir();                    
    }

    const consultaMermas = async () =>{
        setSHCarga(true);
        const recurso = `${env.REACT_APP_API_URL}/api/Mermas?Pais=CL&Centro=${formData.Centro}&Almacen=${formData.Almacen}`;     
        const response = await httpApiGetText(recurso,{
            'Content-Type': 'application/json',
            'Rosen-API-Key': `${env.REACT_APP_Rosen_API_Key}`,
            'Rosen-API-Secret': `${env.REACT_APP_Rosen_API_Secret}`,
        });
        setSHCarga(false);
        const arrDta = await JSON.parse(response.message); 
        if(response.code >= 400){
            console.log("Error Mermas CCosto - 400:", response, recurso);
        }else{
            if (arrDta.length > 0){
                formData = {
                    ...formData, 
                    Centro_Costo: arrDta[0].CENTRO_COSTO
                };             
            }else{
                formData = {
                    ...formData, 
                    Centro_Costo: ""
                };                
            }      
            setFormData({...formData});                  
        }        
    }

    const hndlrOpcionCentro = (cnt: string, nal: any) =>{
        
        if (nal.length === 1){
            formData = {
                ...formData, 
                Centro: cnt, 
                Almacen: nal[0].split("-")[1].trim()
            };
            emp = {...emp, centroDefault: cnt, almacenDefault: nal[0].split("-")[1].trim()}; 
            
            consultaMermas();
        }else{
            formData = {
                ...formData, 
                Centro: cnt, 
                Almacen: ""
            }; 
            emp = {...emp, centroDefault: cnt, almacenDefault: ""};              
        }

        setFormData(formData);   
        dispatch(SetEntornoEmp({...emp})); 
        sessionStorage.setItem("entorno", JSON.stringify(emp));            
    }

    const hndlrOpcionAlmacen = async (evnt: any) =>{
        formData = {...formData, [evnt.target.id]: evnt.target.value.split("-")[0].trim()};
        setFormData(formData);     
        
        emp = {...emp, almacenDefault: evnt.target.value};
        dispatch(SetEntornoEmp({...emp}));  

        sessionStorage.setItem("entorno", JSON.stringify(emp));  
       
        consultaMermas();
    }   
    
    const btnCrear = async () =>{
        
        if (fileName){
            setSHCarga(true);
            const bdy = {
                Pais: "CL",
                Anno: formData.Anno,
                Mes: formData.Mes,
                Centro: formData.Centro,
                Almacen: formData.Almacen,
                CENTRO_COSTO : formData.Centro_Costo,
                FECHA_CREACION : `${formatDate(new Date())}T${formatTime(new Date())}`,
                USU_CREACION: emp.user,
                ESTADO_MERMA : 1,
                Observacion : formData.Descripcion,
                Detalle: [...codigos]
            };
              
            const recurso = `${env.REACT_APP_API_URL}/api/Mermas`;     
            const response = await httpApiPostText(recurso,"POST", {
                'Content-Type': 'application/json',
                'Rosen-API-Key': `${env.REACT_APP_Rosen_API_Key}`,
                'Rosen-API-Secret': `${env.REACT_APP_Rosen_API_Secret}`,
            }, bdy);
            setSHCarga(false);
            const arrDta = await JSON.parse(response.message);    
            if(response.code >= 400){
                arrDta.DetalleErrores.map((item: any)=>MsgApiResponse.push(`${item.ID} - ${item.MENSAJE_ERROR}`));
                setMsgApiResponse(MsgApiResponse);
                setShowMsgApiResponse(true);
            }else{     
                const aux: any = [];
                arrDta.Detalle.map((item: any) =>{
                    const obj = {
                        NRO_SOLICITUD: item.NRO_SOLICITUD,
                        INV_ITEM_ID: item.INV_ITEM_ID,
                        CANTIDAD: item.CANTIDAD,
                        FECHA: item.FECHA,
                        USUARIO: item.USUARIO,
                    };
                    aux.push({...obj});
                });
                DetalleMerma = [...aux];
                setDetalleMerma(DetalleMerma);

                setSolicitud(arrDta.NRO_SOLICITUD + " - " + arrDta.OBSERVACION);
                setShowResponseOk(true);
            }                    
        }else{
            msgAdvertencia = "No ha seleccionado un archivo con los códigos a procesar.";
            setMsgAdvertencia(msgAdvertencia);
            setShowAdvertencia(true);
        }
    }  

    const readExcel = (file: any) => {

        const promise = new Promise((resolve, reject) => {
            
            const fileReader = new FileReader();
            fileReader.readAsArrayBuffer(file);

            fileReader.onload = (e: any) => {
                const bufferArray = e.target.result;
                const wb = XLSX.read(bufferArray, { type: "buffer" });
                const wsname = wb.SheetNames[0];
                const ws = wb.Sheets[wsname];
                const data = XLSX.utils.sheet_to_json(ws);
                resolve(data);
            };

            fileReader.onerror = (error) => {
            reject(error);
            };

        });

        promise.then((d: any) => {
            // Verifica si el archivo tiene datos
            // osea, contiene valores en los encabezados SKU y CANTIDAD
            if (d.length <= 0){
                setHbltBtnAbrir(true);                
            }else{
                // verifica si dentro de los datos, hay encabezados llamados SKU y CANTIDAD en mayúsculas
                if (d[0].SKU && d[0].CANTIDAD){
                    const arrDetail: any = [];
                    d.map((datoFind: any)=>{   
                        const skuDet = {
                            Pais: "CL",
                            Anno: formData.Anno,
                            Mes: formData.Mes,
                            Centro: formData.Centro,
                            Almacen: formData.Almacen,
                            Inv_item_id: datoFind.SKU,
                            CANTIDAD: datoFind.CANTIDAD,
                            FECHA :`${formatDate(new Date())}T${formatTime(new Date())}`,
                            USUARIO: emp.user
                        };
                        arrDetail.push(skuDet);
                    }); 
                    setCodigos([...arrDetail]); 
                    setHbltBtnAbrir(false);
                }else{
                    setHbltBtnAbrir(true);
                }
            }
        });

        return false;
    };

    const getDescripcionHandler = (desc: string) =>{
        setShowDesc(false);
        formData = {...formData, Descripcion: desc}
        setFormData({...formData});
        btnCrear();            
    }  

    useEffect(()=>{

        const preFiltros = () =>{
            if ((emp.periodoDefault !== "")){
                formData = { ...formData, Anno: emp.periodoDefault.split("-")[0],  Mes: emp.periodoDefault.split("-")[1]};
                setFormData(formData);                                         
            }  
            if ((emp.centroDefault !== "")){
                formData = { ...formData, Centro: emp.centroDefault.split("-")[0].trim()};
                setFormData(formData);                                        
            }    
            if ((emp.almacenDefault !== "")){
                formData = { ...formData, Almacen: emp.almacenDefault.split("-")[0].trim()};
                setFormData(formData);                                        
            }  

            ValidaBtnAbrir(); 
        }

        preFiltros();   
        
        if ((formData.Anno !== "") && (formData.Centro !== "") && (formData.Almacen !== "")){
            consultaMermas();
        }

    }, []);    
     
    return(
        <Container fluid>
            <BarraMenuHz/>  
            <div >
                <div className="text-primary ms-3 mt-3">
                        Home / Mermas / Solicitud de Mermas
                </div>
                <div className="h4 mt-4 mb-4 ms-3">SOLICITUD DE MERMAS</div>
            </div> 
            <Container fluid>  

                <Accordion  className="mb-4" defaultActiveKey={['0']} alwaysOpen>
                    <Accordion.Item eventKey="0" className="border shadow ">
                        <Accordion.Header><div className=" h5 text-dark">PARÁMETROS MERMAS</div></Accordion.Header>
                        <Accordion.Body>
                            <label className="h6 mb-3">Recuerda completar todos los campos con *</label> 
                            <div className="align-middle">
                                <FaCalendarAlt className="h5" /> 
                                <label className="ms-2 h4 ">Periodo</label>
                            </div>
                            <hr className="t-0 m-0 mb-3" />

                            <div  className="d-flex flex-column col-6">
                                <label className="m-2 ">Mes/Año</label>
                                <input type="month" id="periodo" size={8}  className="ms-2  form-control"
                                    value={`${formData.Anno}-${formData.Mes}`}
                                    onChange={hndlrPeriodo} 
                                    min="1990-01" max="9999-12"
                                />
                            </div>
                            <div className="align-middle mt-4">
                                <FaTruck className="h5" /> 
                                <label className="ms-2 h4 ">Centro y Almacen</label>
                            </div>
                            <hr className="t-0 m-0 mb-3" /> 

                            <div className="row d-flex flex-row mb-3">
                                <Container fluid className="mb-3 col-md-12 col-lg-6" >
                                    <label className="form-label">Seleccionar Centro de distribución</label>    
                                    <div><SelectCentro id="Centro" OnSeleccion={hndlrOpcionCentro}  default={emp.centroDefault} /></div>
                                </Container>
                                <Container fluid className="mb-3  col-md-12 col-lg-6" >
                                    <label className="form-label">Seleccionar Almacen </label>    
                                    <div ><SelectAlmacenes  id="Almacen" centro={formData.Centro}  OnSeleccion={hndlrOpcionAlmacen}   default={emp.almacenDefault} /></div>
                                </Container>                               
                            </div>
                            <div className="row  mb-3">
                                <div className=" col-md-12 col-lg-6" >
                                    <label className="form-label">Centro de costo</label>    
                                    <div>                                
                                        <input type="text" className="ms-1 form-control" value={`${formData.Centro_Costo}`} disabled />
                                    </div>
                                </div>  
                                <div className=" col-md-12 col-lg-6" >
                                    <label className="form-label">Carga archivo (.xls)</label>    
                                    <form id="fileCodigo">                                
                                        <input type="file" className="ms-1 form-control"
                                            disabled = {(formData.Anno === "") || (formData.Centro === "") || (formData.Almacen === "") || (formData.Centro_Costo === "")}
                                            onChange={(e: any) => {
                                                fileName = e.target.files[0];
                                                if (fileName){
                                                    setFilename(fileName)
                                                    readExcel(fileName);                                                    
                                                }else{
                                                    setFilename({});
                                                    setHbltBtnAbrir(true);
                                                }
                                            }} accept="application/xlsx, .xlsx" />
                                    </form>
                                </div>                                                                
                            </div>                            

                            <div className="row ">
                                <div className=" col-md-12 col-lg-12 text-end">
                                    <Button type="button" style={{backgroundColor:"#00B5B8"}} className=" btn border border-0" 
                                        disabled={hbltBtnAbrir} onClick={() => setShowDesc(true)}
                                    >
                                        Crear Solicitud
                                    </Button>
                                </div>                                
                            </div>

                        </Accordion.Body>
                    </Accordion.Item>
                </Accordion>  

                <Accordion className="mt-1" defaultActiveKey={['0']} alwaysOpen>
                    <Accordion.Item eventKey="0" className="border shadow ">
                        <Accordion.Header><div className=" h5 text-dark">Solicitud: {solicitud}</div></Accordion.Header>
                        <Accordion.Body>
                            <div className="">
                                <DataTable 
                                    columns={columnas}
                                    data={DetalleMerma}
                                    paginationComponentOptions={pagOptions}
                                />                                
                            </div>
                        </Accordion.Body>
                    </Accordion.Item>
                </Accordion> 

            </Container>
            {showMsgApiResponse && <MsgMessageDialog 
                Title="Creación de Mermas"
                Icon={false}
                Show={showMsgApiResponse}
                BtnNokName=""
                BtnOkName="Cerrar"
                Message={MsgApiResponse}
                HandlerdClickOk={()=>{
                    setMsgApiResponse([]);
                    setShowMsgApiResponse(false)}
                }
                HandlerdClickNok={null}
            />} 
            {showAdvertencia && <MsgDialog 
                Title="Creación de Mermas"
                Icon="!"
                Show={showAdvertencia}
                BtnNokName=""
                BtnOkName="Cerrar"
                Message={msgAdvertencia}
                HandlerClickOk={()=>setShowAdvertencia(false)}
                HanlerdClickNok={null}
            />} 
            {showDesc && <ModalDescripcion 
                Title="Descripción de Mermas"
                Icon="!"
                Show={showDesc}
                BtnNokName="Cancelar"
                BtnOkName="Aceptar"
                Descripcion={formData.Descripcion}
                HandlerClickOk={getDescripcionHandler}
                HanlerdClickNok={() => setShowDesc(false)} 
            />}  
            {showResponseOk && <MsgDialog 
                Title="Merma creada"
                Icon="!"
                Show={showResponseOk}
                BtnNokName=""
                BtnOkName="Cerrar"
                Message={"Solicitud: " + solicitud}
                HandlerClickOk={()=>{
                    setShowResponseOk(false);
                    setFilename("");
                    const form = document.getElementById('fileCodigo') as HTMLFormElement;
                    form.reset();
                }}
                HanlerdClickNok={null}
            />}                        
                         
            {/******* Carga masiva *******************************/}          
            <LocalMsgModalSpinner 
                Show={sHCarga}
                text="Un momento por favor, procesando..."
                color="#FF7588"
            />             
        </Container>    
    );
}

export default SolicitudMermasPage;