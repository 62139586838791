import { Button } from 'react-bootstrap';
import Modal from 'react-bootstrap/Modal';
import { ChangeEvent, useEffect, useState } from 'react';
import LocalMsgModalSpinner from '../../../../components/LocalMsgModalSpinner';
import { httpApiDownloadFile, httpApiGetText } from '../../../../lib';
import * as env from '../../../../env';
import DataTable from 'react-data-table-component';
import MsgDialog from '../../../../components/MsgDialog';
import { useSelector } from 'react-redux';
import { FaFileDownload, FaRegTrashAlt } from 'react-icons/fa';

const ModalArchivos = (props: {Show: boolean, Title: string, Icon: string, Message: string, 
                  BtnOkName: string, BtnNokName: string, 
                  HandlerClickOk: any, HanlerdClickNok: any, item: any}) => {
    let emp: any = useSelector((state: any) => state.emp);  
    const [sHCarga, setSHCarga] = useState(false);  
    const [showCarga, setShowCarga] = useState(false);  
    const [msg, setMsg] = useState("");      
    const [filelist, setFilelist] = useState<any>([]);
    let [fileName, setFilename] = useState("");
    const [fileList, setFileList] = useState<FileList | null>(null);
    const [filetoErase, setfiletoErase] = useState<any>();     
    const [showElimaFile, setShowElimaFile] = useState(false);        

    let columnas = [
        { name: "Sku", selector: (row:any) => row.INV_ITEM_ID, sortable: true,  width:"90px"},     
        { name: "Nombre archivo", selector: (row:any) => row.MERMA_NOMBRE_ARCHIVO, sortable: true,  width:"150px", wrap: true},
        { name: "Nombre subido", selector: (row:any) => row.MERMA_ARCHIVO_UPLOAD, sortable: true,  width:"170px", wrap: true},   
        { name: "Fecha subida", selector: (row:any) => row.MERMA_FECHA_SUBIDA, sortable: true,  width:"160px", wrap: true},  
        { name: "Usuario", selector: (row:any) => row.MERMA_USUARIO, sortable: true,  width:"100px", wrap: true},       
        { name: "Acción", selector: (row:any) => row.Acciones, width:"100px"},                                      
    ];

    const GetFilesList = async () => {

        setSHCarga(true);
        const recurso = `${env.REACT_APP_API_URL}/api/ArchivosMermas?Pais=CL&Anno=${props.item.ANNO}&Mes=${props.item.MES}&Centro=${props.item.CENTRO}&Almacen=${props.item.ALMACEN}&Solicitud=${props.item.NRO_SOLICITUD}&Sku=${props.item.INV_ITEM_ID}`;     
        const response = await httpApiGetText(recurso, {
            'Rosen-API-Key': `${env.REACT_APP_Rosen_API_Key}`,
            'Rosen-API-Secret': `${env.REACT_APP_Rosen_API_Secret}`,
        });
        if (response.code >= 400){
            console.log("Error en el servicio de la Api");
        }else{
            const dt = await JSON.parse(response.message);  
            const aux: any = [];            
            dt.map((item: any, index:number) =>{
                const obj = {
                    INV_ITEM_ID: item.INV_ITEM_ID,
                    MERMA_NOMBRE_ARCHIVO: item.MERMA_NOMBRE_ARCHIVO,
                    MERMA_ARCHIVO_UPLOAD: item.MERMA_ARCHIVO_UPLOAD,
                    MERMA_FECHA_SUBIDA: item.MERMA_FECHA_SUBIDA,
                    MERMA_USUARIO: item.MERMA_USUARIO,                    
                    Acciones: <div  className=' d-flex gap-1' >   
                                {
                                    <div  className=' d-flex gap-1' >
                                        <a href="#!" id={`${index}`} onClick={()=> descargaArchivo(item.MERMA_NOMBRE_ARCHIVO)}>
                                            <FaFileDownload   title="Descarga" color={'white'}  className='bg-primary rounded-circle p-1 h4'/>                                                              
                                        </a> 
                                    </div>    
                                }                                      
                                {
                                    !((props.item.ESTADO_MERMA === 4) || (props.item.ESTADO_MERMA === 5)) ?
                                        <div  className=' d-flex gap-1' >
                                            <a href="#!" id={`${index}`} onClick={()=>{
                                                    console.log("a Borrar: ", item);
                                                    setfiletoErase(item);
                                                    setShowElimaFile(true);
                                                }}>
                                                <FaRegTrashAlt  title="Eliminar anexo" color={'white'}  className='bg-danger rounded-circle p-1 h4'/>                                                              
                                            </a> 
                                        </div>  
                                     : null     
                                }                                                                                              
                            </div>                 
                };
                aux.push({...obj});
            });       
            setFilelist([...aux]);
        }
        setSHCarga(false);     
    }

    const handleUploadClick =async () => {

        setSHCarga(true);
        const data = new FormData();

        if (!fileList) {
            return;
        } 

        for (let idx = 0; idx < fileList.length; ++idx){
            data.append(`file-${idx}`, fileList[idx], fileList[idx].name);
        }  

        // 👇 Uploading the file using the fetch API to the server
        const recurso = `${env.REACT_APP_API_URL}/api/ArchivosMermas?Pais=CL&Anno=${props.item.ANNO}&Mes=${props.item.MES}&Centro=${props.item.CENTRO}&Almacen=${props.item.ALMACEN}&Solicitud=${props.item.NRO_SOLICITUD}&Sku=${props.item.INV_ITEM_ID}&Usuario=${emp.user}`; 
        console.log("reciurso : ", recurso);                
        const response = await fetch(recurso, {
                                        method: 'POST',
                                        body: data,
                                        // 👇 Set headers manually for single file upload
                                        headers: {
                                            'Rosen-API-Key': `${env.REACT_APP_Rosen_API_Key}`,
                                            'Rosen-API-Secret': `${env.REACT_APP_Rosen_API_Secret}`         
                                        },
                                    });
        const res = await response.json();
        setSHCarga(false);
        let msg = "";
        if (response.status >= 400){
            msg = res.Message;  
        }else{
            msg = "Archivo cargado con éxito.";  
        }
        setMsg(msg);     
        setShowCarga(true);
        GetFilesList();    
        
        const form = document.getElementById('fileCodigo') as HTMLFormElement;
        form.reset();
        setFilename("");        
    };

    const handleFileChange = (e: ChangeEvent<HTMLInputElement>) => {
        if (e.target.files && e.target.files?.length > 0){
            setFileList(e.target.files);
            setFilename("" + e.target.files?.length);
        }else{
            setFilename("");
        }

    }

    const DeleteFile = async () => {

        setSHCarga(true); 
        setShowElimaFile(false);

        const recurso = `${env.REACT_APP_API_URL}/api/ArchivosMermas?Pais=CL&Anno=${props.item.ANNO}&Mes=${props.item.MES}&Centro=${props.item.CENTRO}&Almacen=${props.item.ALMACEN}&` + 
                                                `Solicitud=${props.item.NRO_SOLICITUD}&Sku=${props.item.INV_ITEM_ID}&NombreArchivo=${filetoErase.MERMA_NOMBRE_ARCHIVO}`;                
        const response = await fetch(recurso, {
                                        method: 'DELETE',
                                        // 👇 Set headers manually for single file upload
                                        headers: {
                                            'Rosen-API-Key': `${env.REACT_APP_Rosen_API_Key}`,
                                            'Rosen-API-Secret': `${env.REACT_APP_Rosen_API_Secret}`         
                                        },
                                    });  
        const res = await response.json();
        let msg = "";
        if (response.status >= 400){
            msg = res;  
        }else{
            msg = "Archivo Eliminado con éxito.";  
            GetFilesList();            
        }
        setMsg(msg);     
        setSHCarga(false);  
        setShowCarga(true); 
    }    

    const download = (file: any) => {
        const link = document.createElement('a')
        const url = URL.createObjectURL(file)
      
        link.href = url
        link.download = file.name
        document.body.appendChild(link)
        link.click()
      
        document.body.removeChild(link)
        window.URL.revokeObjectURL(url)
    }
    
    const descarga = (data: any, fileName: string) =>{
    
        const reader = new FileReader();        
    
        reader.onload = (evt: any) => {
    
            const fl = new File([evt.target.result], fileName, {type: "application/octet-stream"});
            download(fl);
        };      
    
        reader.readAsArrayBuffer(data);
    }

  const descargaArchivo = async (nmFile: string) => {
    setSHCarga(true);
    const recurso = `${env.REACT_APP_API_URL}/api/ArchivosMermas?Pais=CL&Anno=${props.item.ANNO}&Mes=${props.item.MES}&Centro=${props.item.CENTRO}&Almacen=${props.item.ALMACEN}&Solicitud=${props.item.NRO_SOLICITUD}&Sku=${props.item.INV_ITEM_ID}&NombreArchivo=${nmFile}`;      
    const response = await httpApiDownloadFile(recurso, {
        'Content-Type': 'application/json',
        'Rosen-API-Key': `${env.REACT_APP_Rosen_API_Key}`,
        'Rosen-API-Secret': `${env.REACT_APP_Rosen_API_Secret}`,
    });
    if (response.code >= 400){  
      setMsg("Se ha presentado un error al momento de descargar el archivo!!!");  
      setShowCarga(true);
    }else{
      const data: any = (response.message); 
      descarga(data, nmFile);      
    }
    setSHCarga(false);
  }  
    
    useEffect(()=>{
        
        GetFilesList();

    }, []);
    
 return (
      <div>
        <Modal show={props.Show} centered={true} size="lg" >
            <Modal.Header  >
                <Modal.Title className='h3 text-center'>
                    {                        
                        props.Title
                    }
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                { ((props.item.ESTADO_MERMA === 4) || (props.item.ESTADO_MERMA === 5)) || ((props.item.ESTADO_MERMA === 2 && (emp.permisoMermaSupervisor || emp.permisoMermaControlInterno)) || (props.item.ESTADO_MERMA === 1)) ? 
                        <div className='row'>
                            <div className="col-md-12 col-lg-9" >
                                <label className="form-label">Carga archivo</label>    
                                <form id="fileCodigo">                                
                                    <input type="file" className="ms-1 form-control" id="filename"
                                        onChange={handleFileChange}
                                        accept="*.*"
                                    />
                                </form>
                            </div>   
                            <div className='d-flex justify-content-center align-items-center col-md-12 col-lg-3'>
                                <Button type="button" className="btn btn-success " onClick={handleUploadClick} disabled={fileName === ""} >
                                    Adjuntar archivo
                                </Button>                                    
                            </div>          
                        </div>
                        : null
                }
                <div className='row'>
                    <div className="col-md-12 col-lg-12">
                        <DataTable 
                            columns={columnas}
                            data={filelist}
                        />                                
                    </div>                      
                </div>
            </Modal.Body>                
            <Modal.Footer className=''>
                {
                    (props.BtnOkName) 
                        ? 
                        <Button type="button" className="btn btn-success btnColorOk" onClick={()=>alert("Yó...")} 

                        >
                            {props.BtnOkName}
                        </Button>
                        : <div></div>
                }      
                {
                    (props.BtnNokName) 
                        ? 
                        <Button type="button" className="btn btn-danger btnColorNOk" onClick={props.HanlerdClickNok}>
                            {props.BtnNokName}
                        </Button>
                        : <div></div>
                }                                      
            </Modal.Footer>
        </Modal>
        {showCarga && <MsgDialog
            Show={showCarga}
            Title='Carga de Documento'
            BtnOkName='Cerrar'
            HandlerClickOk={()=>setShowCarga(false)}
            Message={msg}
            Icon='x'
            BtnNokName=''
            HanlerdClickNok={null}
        />}      

        {showElimaFile && <MsgDialog 
            Title="Elimina SKU"
            Icon="!"
            Show={showElimaFile}
            BtnNokName="Cancelar"
            BtnOkName="Continuar"
            Message="¿Está usted seguro de querer eliminar este archivo?"
            HandlerClickOk={DeleteFile}
            HanlerdClickNok={()=>setShowElimaFile(false)}
        />}   

        {sHCarga && <LocalMsgModalSpinner 
                    Show={sHCarga}
                    text="Un momento porfavor, procesando..."
                    color="#FF7588"
                /> }           
      </div>
  );
}

export default ModalArchivos;