import { Button } from 'react-bootstrap';
import Modal from 'react-bootstrap/Modal';
import { ChangeEvent, useEffect, useState } from 'react';
import LocalMsgModalSpinner from '../../../../components/LocalMsgModalSpinner';
import { httpApiDownloadFile, httpApiGetText } from '../../../../lib';
import * as env from '../../../../env';
import DataTable from 'react-data-table-component';
import MsgDialog from '../../../../components/MsgDialog';
import { useSelector } from 'react-redux';
import { FaFileDownload, FaRegTrashAlt } from 'react-icons/fa';

const ModalArchivos = (props: {Show: boolean, Title: string, Icon: string, Message: string, 
                  BtnOkName: string, BtnNokName: string, 
                  HandlerClickOk: any, HanlerdClickNok: any, item: any}) => {
    let emp: any = useSelector((state: any) => state.emp);  
    const [sHCarga, setSHCarga] = useState(false);  
    const [showCarga, setShowCarga] = useState(false);  
    const [msg, setMsg] = useState("");      
    const [filelist, setFilelist] = useState<any>([]);    

    let columnas = [
        { name: "Nro. Solicitud", selector: (row:any) => row.NRO_SOLICITUD, sortable: true,  width:"150px", right: true},     
        { name: "Sku", selector: (row:any) => row.INV_ITEM_ID, sortable: true,  width:"120px"},          
        { name: "Nombre archivo", selector: (row:any) => row.MERMA_NOMBRE_ARCHIVO, sortable: true,  width:"250px", wrap: true},      
        { name: "Acción", selector: (row:any) => row.Acciones, width:"100px"},                                      
    ];

    const GetFilesList = async () => {

        setSHCarga(true);
        const recurso = `${env.REACT_APP_API_URL}/api/MermasAutorizaciones?Pais=CL&Anno=${props.item.ANNO}&Mes=${props.item.MES}&Centro=${props.item.CENTRO}&Almacen=${props.item.ALMACEN}&Solicitud=${props.item.NRO_SOLICITUD}`;     
        const response = await httpApiGetText(recurso, {
            'Rosen-API-Key': `${env.REACT_APP_Rosen_API_Key}`,
            'Rosen-API-Secret': `${env.REACT_APP_Rosen_API_Secret}`,
        });
        if (response.code >= 400){
            console.log("Error en el servicio de la Api");
        }else{
            const dt = await JSON.parse(response.message);  
            console.log("adjntos ", dt);
            const aux: any = [];            
            dt.map((item: any, index:number) =>{
                const obj = {
                    NRO_SOLICITUD: item.NRO_SOLICITUD,
                    INV_ITEM_ID: item.INV_ITEM_ID,
                    MERMA_NOMBRE_ARCHIVO: item.MERMA_NOMBRE_ARCHIVO,                
                    Acciones: <div  className=' d-flex gap-1' >   
                                {
                                    <div  className=' d-flex gap-1' >
                                        <a href="#!" id={`${index}`} onClick={()=> descargaArchivo(item)}>
                                            <FaFileDownload   title="Descarga" color={'white'}  className='bg-primary rounded-circle p-1 h4'/>                                                              
                                        </a> 
                                    </div>    
                                }                                                                                           
                            </div>                 
                };
                aux.push({...obj});
            });       
            setFilelist([...aux]);
        }
        setSHCarga(false);     
    }  

    const download = (file: any) => {
        const link = document.createElement('a')
        const url = URL.createObjectURL(file)
      
        link.href = url
        link.download = file.name
        document.body.appendChild(link)
        link.click()
      
        document.body.removeChild(link)
        window.URL.revokeObjectURL(url)
    }
    
    const descarga = (data: any, fileName: string) =>{
    
        const reader = new FileReader();        
    
        reader.onload = (evt: any) => {
    
            const fl = new File([evt.target.result], fileName, {type: "application/octet-stream"});
            download(fl);
        };      
    
        reader.readAsArrayBuffer(data);
    }

    const descargaArchivo = async (item: any) => {

        setSHCarga(true);
        const recurso = `${env.REACT_APP_API_URL}/api/ArchivosMermas?Pais=CL&Anno=${item.ANNO}&Mes=${item.MES}&Centro=${item.CENTRO}&Almacen=${item.ALMACEN}&Solicitud=${item.NRO_SOLICITUD}&Sku=${item.INV_ITEM_ID}&NombreArchivo=${item.MERMA_NOMBRE_ARCHIVO}`;      
        const response = await httpApiDownloadFile(recurso, {
            'Content-Type': 'application/json',
            'Rosen-API-Key': `${env.REACT_APP_Rosen_API_Key}`,
            'Rosen-API-Secret': `${env.REACT_APP_Rosen_API_Secret}`,
        });
        if (response.code >= 400){  
            setMsg("Se ha presentado un error al momento de descargar el archivo!!!");  
            setShowCarga(true);
        }else{
            const data: any = (response.message); 
            descarga(data, item.MERMA_NOMBRE_ARCHIVO);      
        }
        setSHCarga(false);
    }  
    
    useEffect(()=>{

        GetFilesList();

    }, []);
    
 return (
      <div>
        <Modal show={props.Show} centered={true} size="lg" >
            <Modal.Header  >
                <Modal.Title className='h3 text-center'>
                    {                        
                        props.Title
                    }
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className='row'>
                    <div className="col-md-12 col-lg-12">
                        <DataTable 
                            columns={columnas}
                            data={filelist}
                        />                                
                    </div>                      
                </div>
            </Modal.Body>                
            <Modal.Footer className=''>
                {
                    (props.BtnOkName) 
                        ? 
                        <Button type="button" className="btn btn-success btnColorOk" onClick={()=>alert("Yó...")} 

                        >
                            {props.BtnOkName}
                        </Button>
                        : <div></div>
                }      
                {
                    (props.BtnNokName) 
                        ? 
                        <Button type="button" className="btn btn-danger btnColorNOk" onClick={props.HanlerdClickNok}>
                            {props.BtnNokName}
                        </Button>
                        : <div></div>
                }                                      
            </Modal.Footer>
        </Modal>
        {sHCarga && <LocalMsgModalSpinner 
                    Show={sHCarga}
                    text="Un momento porfavor, procesando..."
                    color="#FF7588"
                /> }           
      </div>
  );
}

export default ModalArchivos;