import { Button } from 'react-bootstrap';
import Modal from 'react-bootstrap/Modal';
import './style.css';
import { useState } from 'react';
import LocalMsgModalSpinner from '../../../../components/LocalMsgModalSpinner';
import { httpApiGetText } from '../../../../lib';
import * as env from '../../../../env';

const ModalAdd = (props: {Show: boolean, Title: string, Icon: string, Message: string, 
                  BtnOkName: string, BtnNokName: string, 
                  HandlerClickOk: any, HanlerdClickNok: any}) => {

    const [sHCarga, setSHCarga] = useState(false);
    const [sku, setSku] = useState(0);  
    const [descripcion, setDescripcion] = useState("");
    const [cantidad, setCantidad] = useState("0");    

    const GetInfoProductos = async (producto: string) => {

        setSHCarga(true);
        const recurso = `${env.REACT_APP_API_URL}/api/Producto?CodProducto=${producto}`;     
        const response = await httpApiGetText(recurso, {
            'Rosen-API-Key': `${env.REACT_APP_Rosen_API_Key}`,
            'Rosen-API-Secret': `${env.REACT_APP_Rosen_API_Secret}`,
        });
        if (response.code >= 400){
            console.log("No se encontraron registros asociados al material indicado.");
        }else{
            const dt = await JSON.parse(response.message);   
            setDescripcion(dt.Descripcion);
        }
        setSHCarga(false);     
    }
                    
 return (
      <div>
        <Modal show={props.Show} centered={true} size="lg" >
            <Modal.Header  >
                <Modal.Title className='h3 text-center'>
                    {                        
                        props.Title
                    }
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className='row'>
                    <div className='col-lg-3 col-md-12'>
                        <label htmlFor="sku">SKU</label>
                        <input className='form-control' type="number" value={sku} id="sku" 
                            onChange={(evnt: any)=>{
                                setDescripcion("");
                                setSku(evnt.target.value);
                            }} 
                            onBlur={(evnt: any)=> GetInfoProductos(evnt.target.value)}
                            min={0}
                        />                        
                    </div>
                    <div className='col-lg-6 col-md-12'>
                        <label htmlFor="Descripcionku">Descripción</label>
                        <input className='form-control' type="text" value={descripcion} id="Descripcion" disabled/>                        
                    </div>                    
                    <div className='col-lg-3 col-md-12'>
                        <label htmlFor="cantidad">Cantidad</label>
                        <input className='form-control text-end' type="number" value={cantidad} id="cantidad" 
                            onChange={(evnt: any)=>{
                                setCantidad(evnt.target.value);
                            }} 
                            disabled={descripcion === ""} 
                            min={0}
                        />                     
                    </div>                  
                </div>
            </Modal.Body>                
            <Modal.Footer className=''>
                {
                    (props.BtnOkName) 
                        ? 
                        <Button type="button" className="btn btn-success btnColorOk" onClick={()=>props.HandlerClickOk({sku: sku, cantidad: cantidad})} disabled={parseInt(cantidad) <= 0} >
                            {props.BtnOkName}
                        </Button>
                        : <div></div>
                }      
                {
                    (props.BtnNokName) 
                        ? 
                        <Button type="button" className="btn btn-danger btnColorNOk" onClick={props.HanlerdClickNok}>
                            {props.BtnNokName}
                        </Button>
                        : <div></div>
                }                                      
            </Modal.Footer>
        </Modal>
        {sHCarga && <LocalMsgModalSpinner 
                    Show={sHCarga}
                    text="Un momento porfavor, procesando..."
                    color="#FF7588"
                /> }           
      </div>
  );
}

export default ModalAdd;