import { useEffect } from "react";
import { Button} from "react-bootstrap";
import { FaArrowsAltH, FaArrowsAltV, FaBacterium, FaBarcode, FaBoxOpen, FaBoxTissue, FaBuffer, FaClipboardList, FaCloudDownloadAlt, FaElementor, FaExchangeAlt, FaFileAlt, FaFileMedicalAlt, FaFlask, FaFolder, FaListOl, FaMarkdown, FaPowerOff, FaPrint, FaQuestion, FaRegCalendarAlt, FaRegChartBar, FaRegClock, FaSave, FaSearch, FaShareAltSquare, FaTable, FaTh, FaTools, FaWarehouse } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { SetEntornoEmp } from "../../redux/store/Actions";
import * as env from '../../env';
import './style.css';

const logo = require('./logo-rosen2.png');

const BarraMenuHz = () => {
   
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const emp: any = useSelector((state: any) => state.emp);

    useEffect(() => {

        const init = async () =>{
            if (emp.user === "") {
                const ss = await sessionStorage.getItem("entorno");
                if (ss){
                    let sesionData = JSON.parse(ss);
                    dispatch(SetEntornoEmp({...sesionData}));
                } else{
                    navigate('/');  
                }              
            }            
        }

        init();

    },[]);

    const hndlrSalir = () =>{
        dispatch(SetEntornoEmp({pais:"", user:""}));
        navigate('/');
    }

    return (
        <nav className="navbar navbar-expand-lg navbar-dark bg-light">
            <div className="container-fluid  shadow rounded"  style={{backgroundColor:"#404E67"}}>
                {/* Imagén marca fluida */}
                <div className="m-2" >
                    <a  href="#!">
                        <img src={logo} alt="" width={150} className="img-fluid m-2"/>
                    </a>
                </div>
                {/* Ícono de hamburguesa en modo responsive*/}
                <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                    <span className="navbar-toggler-icon"></span>
                </button>

                <div className="collapse navbar-collapse" id="navbarSupportedContent">
                    <ul className="navbar-nav me-auto mb-2 mb-lg-0">

                        {/* Menú Dashboard */} 
                        <li className="nav-item dropdown  me-3 ">
                            <Link to="/dashboard" className="nav-link dropdown-toggle text-decoration-none me-2 d-flex flex-row align-items-center gap-1"  id="navbarDropdownDashboard" role="button" data-bs-toggle="dropdown" aria-expanded="false" key={0}> <FaWarehouse /> Dashboard</Link>
                            <ul className="dropdown-menu" aria-labelledby="navbarDropdown">                               
                                <li><Link className={`dropdown-item ${(emp.tokens.filter((tkn: string)=> tkn === env.REACT_APP_DASHBOARD).length > 0) ? "": "disabled" }`} to="/dashboard"  >Dashboard</Link></li>
                            </ul>    
                        </li>

                        {/* Menú Impresiones*/} 
                        <li className="nav-item dropdown  me-3 ">
                            <Link to="/impresiones/etiquetas" className="nav-link dropdown-toggle text-decoration-none me-2 d-flex flex-row align-items-center gap-1"  id="navbarDropdownReferencia" role="button" data-bs-toggle="dropdown" aria-expanded="false" key={0}> <FaPrint /> Impresiones</Link>
                            <ul className="dropdown-menu" aria-labelledby="navbarDropdownReferencia">                               
                                <li><Link className={`dropdown-item ${(emp.tokens.filter((tkn: string)=> tkn === env.REACT_APP_TRATAMIENTOETIQUETAS).length > 0) ? "": "disabled" }`} to="/impresiones/etiquetas" ><FaBarcode /> Etiqueta sin referencias </Link></li>
                            </ul>    
                        </li>

                        {/* Menú Inventario*/} 
                        <li className="nav-item dropdown  me-3 ">
                            <Link to="#!" className="nav-link dropdown-toggle text-decoration-none me-2 d-flex flex-row align-items-center gap-1"  id="navbarDropdownInventario" role="button" data-bs-toggle="dropdown" aria-expanded="false" key={0}> <FaClipboardList /> Inventario</Link>
                            <ul className="dropdown-menu" aria-labelledby="navbarDropdownInventario">                               
                                <li><Link  className={`dropdown-item ${(emp.tokens.filter((tkn: string)=> tkn === env.REACT_APP_GESTIONINVENTARIO).length > 0) ? "": "disabled" }`} to="/inventario/gestioninventario" ><FaShareAltSquare /> Gestión de inventario </Link></li>
                                <li><Link  className={`dropdown-item ${(emp.tokens.filter((tkn: string)=> tkn === env.REACT_APP_GESTIONINVENTARIO).length > 0) ? "": "disabled" }`} to="/inventario/gestionsubinventario" ><FaShareAltSquare />  Gestión Sub-inventario </Link></li>
                                <li><Link  className={`dropdown-item ${(emp.tokens.filter((tkn: string)=> tkn === env.REACT_APP_TRATAMIENTOETIQUETAS).length > 0) ? "": "disabled" }`} to="/inventario/tratamiento" ><FaBarcode /> Tratamiento de etiquetas </Link></li>   
                                <li><Link  className={`dropdown-item `} to="/inventario/documentos" ><FaFileAlt /> Documentos </Link></li>        
{/*                                 <li><Link  className={`dropdown-item ${(emp.tokens.filter((tkn: string)=> tkn === env.REACT_APP_DOCUMENTOS).length > 0) ? "": "disabled" }`} to="/inventario/documentos" ><FaBarcode /> Documentos </Link></li>  */}                                                                                                                         
                            </ul>    
                        </li>

                        {/* Menú Consultas*/} 
                        <li className="nav-item dropdown  me-3 ">
                            <Link to="#!" className="nav-link dropdown-toggle text-decoration-none me-2 d-flex flex-row align-items-center gap-1"  id="navbarDropdownConsultas" role="button" data-bs-toggle="dropdown" aria-expanded="false" key={0}> <FaQuestion /> Consultas</Link>
                            <ul className="dropdown-menu" aria-labelledby="navbarDropdownConsultas">                               
                                <li><Link  className={`dropdown-item ${(emp.tokens.filter((tkn: string)=> tkn === env.REACT_APP_AVANCEINVENTARIO).length > 0) ? "": "disabled" }`} to="/consultas/avanceinventario" ><FaShareAltSquare /> Avance inventario </Link></li>
                                <li><Link  className={`dropdown-item ${(emp.tokens.filter((tkn: string)=> tkn === env.REACT_APP_REPORTEINVENTARIO).length > 0) ? "": "disabled" }`} to="/consultas/reporteinventario" ><FaClipboardList />  Reporte inventario </Link></li>
                                <li><Link  className={`dropdown-item ${(emp.tokens.filter((tkn: string)=> tkn === env.REACT_APP_INFORMESALDOS).length > 0) ? "": "disabled" }`} to="/consultas/informesaldos" ><FaRegChartBar /> Informe de saldos </Link></li>       
                                <li><Link  className={`dropdown-item ${(emp.tokens.filter((tkn: string)=> tkn === env.REACT_APP_DESCARGAMASIVA).length > 0) ? "": "disabled" }`} to="/consultas/descargamasiva" ><FaCloudDownloadAlt /> Descarga masiva </Link></li>                                                                                              
                            </ul>    
                        </li>

                        {/* Menú Reconteo*/} 
                        <li className="nav-item dropdown  me-3 ">
                            <Link to="#!" className="nav-link dropdown-toggle text-decoration-none me-2 d-flex flex-row align-items-center gap-1"  id="navbarDropdownReconteo" role="button" data-bs-toggle="dropdown" aria-expanded="false" key={0}> <FaListOl /> Reconteo</Link>
                            <ul className="dropdown-menu" aria-labelledby="navbarDropdownReconteo">                               
                                <li><Link  className={`dropdown-item ${(emp.tokens.filter((tkn: string)=> tkn === env.REACT_APP_APERTURARECONTEO).length > 0) ? "": "disabled" }`} to="/reconteo/apertura" ><FaBoxOpen /> Apertura de reconteo</Link></li>
                                <li><Link  className={`dropdown-item ${(emp.tokens.filter((tkn: string)=> tkn === env.REACT_APP_AVANCEYCIERRERECONTEO).length > 0) ? "": "disabled" }`} to="/reconteo/avance" ><FaFolder /> Avance y cierre de reconteo </Link></li>                                                                                          
                            </ul>    
                        </li>


                        {/* Menú Muestras*/} 
                        <li className="nav-item dropdown  me-3 ">
                            <Link to="#!" className="nav-link dropdown-toggle text-decoration-none me-2 d-flex flex-row align-items-center gap-1"  id="navbarDropdownMuestras" role="button" data-bs-toggle="dropdown" aria-expanded="false" key={0}> <FaFlask /> Muestras</Link>
                            <ul className="dropdown-menu" aria-labelledby="navbarDropdownMuestras">                               
                                <li><Link  className={`dropdown-item ${(emp.tokens.filter((tkn: string)=> tkn === env.REACT_APP_PERIODOMUESTRA).length > 0) ? "": "disabled" }`} to="/muestras/periodo" ><FaRegCalendarAlt /> Período de muestras</Link></li>
                                <li><Link  className={`dropdown-item ${(emp.tokens.filter((tkn: string)=> tkn === env.REACT_APP_AVANCEYCIERREMUESTRAS).length > 0) ? "": "disabled" }`} to="/muestras/avance" ><FaShareAltSquare /> Avance y cierre de muestras </Link></li>                                                                                          
                                {/* <li><Link  className={`dropdown-item ${(emp.tokens.filter((tkn: string)=> tkn === env.REACT_APP_SALDOSMUESTRA).length > 0) ? "": "disabled" }`} to="/muestras/saldos" ><FaShareAltSquare /> Saldos nuestras </Link></li> */}
                                <li><Link  className={`dropdown-item `} to="/muestras/saldos" ><FaShareAltSquare /> Saldos de muestras </Link></li>         
                                <li><Link  className={`dropdown-item ${(emp.tokens.filter((tkn: string)=> tkn === env.REACT_APP_GESTIONMUESTRAS).length > 0) ? "": "disabled" }`} to="/muestras/gestion" ><FaBacterium /> Gestión de muestras </Link></li>             
                                <li><Link  className={`dropdown-item ${(emp.tokens.filter((tkn: string)=> tkn === env.REACT_APP_DESCARGAMASIVAMUESTRAS).length > 0) ? "": "disabled" }`} to="/muestras/descargamasiva" ><FaMarkdown /> Descarga masiva de muestras </Link></li>                 
                                {/* <li><Link  className={`dropdown-item `} to="/muestras/gestion" ><FaBacterium /> Gestión de muestras </Link></li>               
                                <li><Link  className={`dropdown-item `} to="/muestras/descargamasiva" ><FaMarkdown /> Descarga masiva de muestras </Link></li>   */}                         
                            </ul>    
                        </li>

                        {/* Menú Clasificación Diferencias */} 
                        <li className="nav-item dropdown  me-3 ">
                            <Link to="/clasificacion" className="nav-link dropdown-toggle text-decoration-none me-2 d-flex flex-row align-items-center gap-1"  id="navbarDropdownDashboard" role="button" data-bs-toggle="dropdown" aria-expanded="false" key={0}> <FaBuffer />Clasificación</Link>
                            <ul className="dropdown-menu" aria-labelledby="navbarDropdownDashboard">                               
                                {/*<li><Link className={`dropdown-item ${(emp.tokens.filter((tkn: string)=> tkn === env.REACT_APP_CLASIFICACIONDIFERENCIAS ).length > 0) ? "": "disabled" }`} to="/clasificacion/diferencias"  ><FaArrowsAltV />Diferencias</Link></li> */}
                                <li><Link className={`dropdown-item`} to="/clasificacion/diferencias"  ><FaArrowsAltV />Diferencias Inventario</Link></li>
                                <li><Link className={`dropdown-item`} to="/clasificacion/muestras"  ><FaArrowsAltH />Diferencias Muestras</Link></li>

                            </ul>    
                        </li>  
                        {/* Menú Mermas*/} 
                        <li className="nav-item dropdown  me-3 ">
                            <Link to="#!" className="nav-link dropdown-toggle text-decoration-none me-2 d-flex flex-row align-items-center gap-1"  id="navbarDropdownAuditoria" role="button" data-bs-toggle="dropdown" aria-expanded="false" key={0}> <FaBoxTissue /> Mermas</Link>
                            <ul className="dropdown-menu" aria-labelledby="navbarDropdownAuditoria">                               
                                <li><Link  className={`dropdown-item`}  /* className={`dropdown-item ${(emp.tokens.filter((tkn: string)=> tkn === env.REACT_APP_SOLICITUDMERMAS).length > 0) ? "": "disabled" }`} */ to="/mermas/solicitud" ><FaExchangeAlt /> Solicitud de mermas </Link></li>
                                <li><Link  className={`dropdown-item`}  /* className={`dropdown-item ${(emp.tokens.filter((tkn: string)=> tkn === env.REACT_APP_EDITARMERMAS).length > 0) ? "": "disabled" }`}  */to="/mermas/editar" ><FaFileMedicalAlt /> Editar de mermas </Link></li>
                                <li><Link  className={`dropdown-item`}  /* className={`dropdown-item ${(emp.tokens.filter((tkn: string)=> tkn === env.REACT_APP_GESTIONMERMAS).length > 0) ? "": "disabled" }`} */ to="/mermas/gestion" ><FaElementor /> Gestión de mermas </Link></li>                                                                                                
                            </ul>    
                        </li> 
                        {/* Menú Auditoría*/} 
                        <li className="nav-item dropdown  me-3 ">
                            <Link to="#!" className="nav-link dropdown-toggle text-decoration-none me-2 d-flex flex-row align-items-center gap-1"  id="navbarDropdownAuditoria" role="button" data-bs-toggle="dropdown" aria-expanded="false" key={0}> <FaSearch /> Auditoria</Link>
                            <ul className="dropdown-menu" aria-labelledby="navbarDropdownAuditoria">                               
                                <li><Link  /* className={`dropdown-item`} */  className={`dropdown-item ${(emp.tokens.filter((tkn: string)=> tkn === env.REACT_APP_KARDEXINICIAL).length > 0) ? "": "disabled" }`} to="/auditoria/kardexinicial" ><FaTable /> Kardex inicial </Link></li>
                                <li><Link  /* className={`dropdown-item`}  */ className={`dropdown-item ${(emp.tokens.filter((tkn: string)=> tkn === env.REACT_APP_KARDEXFINAL).length > 0) ? "": "disabled" }`} to="/auditoria/kardexfinal" ><FaTh />  Kardex final </Link></li>
                                <li><Link  /* className={`dropdown-item`}  */ className={`dropdown-item ${(emp.tokens.filter((tkn: string)=> tkn === env.REACT_APP_ROLLFORWRAD).length > 0) ? "": "disabled" }`} to="/auditoria/rollforward" ><FaFileMedicalAlt /> Rollforward </Link></li>       
                                <li><Link  /* className={`dropdown-item`}  */ className={`dropdown-item ${(emp.tokens.filter((tkn: string)=> tkn === env.REACT_APP_DETALLEAJUSTES).length > 0) ? "": "disabled" }`} to="/auditoria/detalle" ><FaSave /> Detalle ajustes </Link></li>                                                                                              
                            </ul>    
                        </li>     
                        {/* Menú Mantenedor */} 
                        {<li className="nav-item dropdown  me-3 ">
                            {/* <Link to="#!" className="nav-link dropdown-toggle text-decoration-none me-2 d-flex flex-row align-items-center gap-1"  id="navbarDropdownMantenedor" role="button" data-bs-toggle="dropdown" aria-expanded="false" key={0}> <FaTools /> Mantenedor</Link> */}
                            <ul className="dropdown-menu" aria-labelledby="navbarDropdownMantenedor">                               
                                {/* <li><Link  className={`dropdown-item`}   to="/mantenedor/horacorte" ><FaRegClock /> Hora Corte </Link></li>                                                                                           */}
                            </ul>    
                        </li>}                                                                  
                    </ul>
                    <div className="d-flex flex-row gap-3 text-light wrap" style={{width:"fit-content"}}>
                        <div className="">
                            <div className="">
                                {emp.pais}
                            </div>
                            <div className="">
                                Hola, {emp.user}
                            </div>
                        </div>
                        <div >
                            <Button type="button" className="border-0 bg-secondary" onClick={hndlrSalir}><FaPowerOff className="h3 mt-1"/></Button>
                        </div>
                    </div>  
                </div>
            </div>
        </nav>        
    );
};

export default BarraMenuHz;