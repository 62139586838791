import { useEffect, useState } from "react";
import { Accordion, Button, Container, Form } from "react-bootstrap";
import { FaBarcode, FaPrint, FaTruck } from "react-icons/fa";
import SelectCentro from "../../../components/Centros";
import LocalMsgModalSpinner from "../../../components/LocalMsgModalSpinner";
import { httpApiGetText, httpApiPostText } from "../../../lib";
import * as env from '../../../env';
import './style.css';
import { useDispatch, useSelector } from "react-redux";
import MsgDialog from "../../../components/MsgDialog";
import MsgModalDialogEspecial from "../../../components/MsgModalDialogEspecial";
import BarraMenuHz from "../../../components/BarraMenoHz";
import { SetEntornoEmp } from "../../../redux/store/Actions";
import SelectAlmacenes from "../../../components/Almacenes";

const EtiquetaSinRefPage = () =>{

    let emp: any = useSelector((state: any) => state.emp);
    const [sHCarga, setSHCarga] = useState(false);
    const [msgResponse, setMsgResponse] = useState("");     
    const [msgDlgShow, setMsgDlgShow] = useState(false);  
    const [msgDlgShowImp, setMsgDlgShowImp] = useState(false);      
    const [msgShowErrorImp, setMsgShowErrorImp] = useState(false);       
    const [opcionesSelect, setOpcionesSelect] = useState([]);
    let [centro1, setCentro1] = useState("");     

    let [formData, setFormData] = useState({CodigoPr:"", unidadMedida:"", descripcion: "", cantEtiqueta:"1", cantEtiquetaImp: "1", urlOpcionImp: "", SizeTag: ""});
    let [formData2, setFormData2] = useState({ codigoCentro2: "", codigoAlmacen: ""});                                
    const dispatch = useDispatch();  

    
    useEffect(()=>{
 
        const revision = () =>{
            if (emp.centroDefault !== ""){
                GetobtenerImps(emp.centroDefault.split("-")[0].trim(), emp.user);
                formData2 = {...formData2, codigoCentro2: emp.centroDefault.split("-")[0].trim()};
                setFormData2({...formData2});                                            
            }  
            if (emp.almacenDefault !== ""){
                formData2 = {...formData2, codigoAlmacen: emp.almacenDefault.split("-")[0].trim()};
                setFormData2({...formData2});                                            
            }         
        }

        revision();

    }, []);

    const PostImpresorasImpresion = async (body: any) =>{

        setSHCarga(true);
        const recurso = `${env.REACT_APP_API_URL}/api/Impresoras`;     
        const res = await httpApiPostText(recurso, "POST", {
            'Content-Type': 'application/json',
            'Rosen-API-Key': `${env.REACT_APP_Rosen_API_Key}`,
            'Rosen-API-Secret': `${env.REACT_APP_Rosen_API_Secret}`,
        }, body);
        const dt = await JSON.parse(res.message);   
        setSHCarga(false);        
        if(res.code >= 400){
            setMsgResponse("Ha habido un problema el genrer el archivo de impresión");
        }else{
            setMsgResponse(dt.Message);
        }
        setMsgDlgShowImp(true); 
    }

    const GetInfoProductos = async (producto: string) => {

        setSHCarga(true);
        const recurso = `${env.REACT_APP_API_URL}/api/Producto?CodProducto=${producto}`;     
        const response = await httpApiGetText(recurso, {
            'Rosen-API-Key': `${env.REACT_APP_Rosen_API_Key}`,
            'Rosen-API-Secret': `${env.REACT_APP_Rosen_API_Secret}`,
        });
        if (response.code >= 400){
            console.log("No se encontraron registros asociados al material indicado.");
        }else{
            const dt = await JSON.parse(response.message);    
            formData = {...formData, descripcion: dt.Descripcion, unidadMedida:dt.UnidadMedida};
            setFormData(formData);
        }
        setSHCarga(false);     

    }

    const GetobtenerImps = async (centro: string, user: string) =>{

            setSHCarga(true);
            const recurso = `${env.REACT_APP_API_URL}/api/Impresoras?Pais=CL&Centro=${centro}&Usuario=${user}`;     
            const data = await httpApiGetText(recurso, {
                'Rosen-API-Key': `${env.REACT_APP_Rosen_API_Key}`,
                'Rosen-API-Secret': `${env.REACT_APP_Rosen_API_Secret}`,
            });
            const dt = await JSON.parse(data.message);    
            setOpcionesSelect(dt);   
            setSHCarga(false);
    }

    const hndlrOpcionCentro = (evnt: any) =>{ 

        console.log(evnt);
        formData.urlOpcionImp = "";
        centro1 = evnt;
        GetobtenerImps(centro1, emp.user);
    }

    const hndlrOpcionCentro2 = (evnt: any, nal: any) =>{

        const cnt = evnt;
        if (nal.length === 1){
            formData2 = {
                ...formData2, 
                codigoCentro2: cnt, 
                "codigoAlmacen": nal[0].split("-")[1].trim()
            };
            emp = {...emp, centroDefault: cnt, almacenDefault: nal[0].split("-")[1].trim()};     
        }else{
            formData2 = {
                ...formData2, 
                codigoCentro2: cnt, 
                "codigoAlmacen": ""
            }; 
            emp = {...emp, centroDefault: cnt, almacenDefault: ""};              
        }
 
        setFormData2(formData2); 

        dispatch(SetEntornoEmp({...emp}));    
        
        sessionStorage.setItem("entorno", JSON.stringify(emp));        
    }

    const hndlrOpcionAlmacen = (evnt: any) =>{


        formData2 = {...formData2, codigoAlmacen: evnt.target.value.split("-")[0].trim()};
        setFormData2(formData2); 

        console.log(formData2);

        emp = {...emp, almacenDefault: evnt.target.value};
        dispatch(SetEntornoEmp({...emp}));   
        
        sessionStorage.setItem("entorno", JSON.stringify(emp));   
    }

    const changeText = (evnt: any) => {
        formData = {...formData, [evnt.target.id]: evnt.target.value.trim()}
        setFormData(formData);      
    }

    const keyPressEnter = (evnt: any) => {
        if ((evnt.keyCode === 13) && (formData.CodigoPr !== "")){
            GetInfoProductos(formData.CodigoPr);    
        }   
    }     

    const hndlBtnImprimir = () => {
        if(formData.urlOpcionImp === ""){
            setMsgShowErrorImp(true);
        }else{
            const bdy = {
                URL: formData.urlOpcionImp,
                ZPL: "",
                Codigo_Producto:formData.CodigoPr,
                Unidad_Medida:formData.unidadMedida,
                Descripcion: formData.descripcion,
                Cantidad_En_Etiqueta:formData.cantEtiqueta,
                Cantidad_De_Etiquetas: formData.cantEtiquetaImp,
                Tipo_Etiqueta: formData.SizeTag
            };

            if ( (formData.urlOpcionImp === "") || 
                (formData.unidadMedida === "") || 
                (formData.cantEtiqueta === "" || parseInt(formData.cantEtiqueta) <= 0) ||
                (formData.cantEtiquetaImp === "" || formData.cantEtiquetaImp === "0") ||
                (formData2.codigoCentro2 === "") ||
                (formData2.codigoAlmacen === "") ||
                (formData.SizeTag === "")){

                    setMsgDlgShow(true);

            }else{
                PostImpresorasImpresion(bdy);           
            }
        }
    }

    const handlerSizeTag = (evnt: any) =>{
        formData = {...formData, SizeTag: evnt.target.value};
        setFormData({...formData});
    } 

    const MsgDlghandlerImpOk = () =>{

        setMsgDlgShowImp(false);
    }  

    return(
        <>
            <Container fluid>
                <BarraMenuHz/>                   
                <Container fluid >                   
                    <div >
                        <div className="text-primary mt-3">
                            Home / Impresiones / Etiqueta sin referencia
                        </div>
                        <div className="h4 mt-4 mb-4">IMPRIMIR ETIQUETAS SIN REFERENCIA</div>
                    </div>
                    <Accordion defaultActiveKey={['0']} alwaysOpen className="mb-4">
                        <Accordion.Item eventKey="0" className="border shadow">
                            <Accordion.Header > <div className=" h5 text-dark"> SELECCIÓN DE IMPRESORA </div></Accordion.Header>
                            <Accordion.Body className=" mt-4">
                                <label className="form-label h6  mb-5">INFORMACIÓN IMPRESORA POR RED</label>  
                                <div className="row ">
                                    <div className="col col-lg-6 col-sm-12 ">
                                        <Container fluid className=" mb-3" >
                                            <label className="form-label">Seleccionar Centro de distribución donde se localiza la Impresora. *</label>    
                                            <div > <SelectCentro id="centro1" OnSeleccion={hndlrOpcionCentro}  default={emp.centroDefault}/></div>
                                        </Container>
                                    </div>  
                                    <div className="col col-lg-6 col-sm-12 ">                                                                  
                                        <Container fluid  className="m-1">
                                            <label className="form-label">Seleccionar Impresora. *</label>
                                            <div className="mb-4"  >
                                            <Form.Select aria-label="Default select example" id="urlOpcionImp"  onChange={changeText}>
                                                <option value={""}>Seleccione la impresora</option>
                                                {
                                                    (opcionesSelect.length > 0 ) ? (opcionesSelect.map((valor: any, index: number)=><option key={index} value={valor.Url}>{valor.Descripcion}</option>)) : ""
                                                }
                                            </Form.Select>  
                                            </div>   
                                        </Container>  
                                    </div>
                                </div>                          
                            </Accordion.Body>
                        </Accordion.Item>
                    </Accordion>
                    <Accordion defaultActiveKey={['0']} alwaysOpen>
                        <Accordion.Item eventKey="0" className="border shadow ">
                            <Accordion.Header><div className=" h5 text-dark"> INFORMACIÓN ETIQUETAS </div></Accordion.Header>
                            <Accordion.Body>
                                <label className="h6 mb-3">Recuerda completar todos los campos que tienen *</label> 
                                <div className="align-middle">
                                    <FaTruck className="h5" /> 
                                    <label className="ms-2 h4 ">Centro y almacen</label>
                                </div>
                                <hr className="t-0 m-0 mb-3" />

                                <div className="d-flex flex-row mb-3">
                                    <Container fluid className="mb-3" >
                                        <label className="form-label">Seleccionar Centro de distribución *</label>    
                                        <div>
                                            <SelectCentro id="codigoCentro2"  
                                                OnSeleccion={hndlrOpcionCentro2}  
                                                default={emp.centroDefault}
                                                centros={emp.centros}
                                            />
                                        </div>
                                    </Container>
                                    <Container fluid className="mb-3" >
                                        <label className="form-label">Seleccionar Almacen *</label>    
                                        <div>
                                            <SelectAlmacenes id="codigoAlmacen" 
                                                centro={formData2.codigoCentro2} 
                                                OnSeleccion={hndlrOpcionAlmacen}  
                                                default={emp.almacenDefault}
                                            />
                                        </div>
                                    </Container>
                                </div>

                                <div className="d-flex flex-column m-2 mb-4">
                                    <label className="form-label">Seleccionar Tipo de Etiqueta *</label>    
                                    <div className="row">
                                        <div className="col col-lg-6 col-md-6">
                                            <select  className="form-select" id="sizeTag" aria-label="Default select example" value={formData.SizeTag} onChange={handlerSizeTag}>
                                                <option key={-1} value={""}>Seleccione una opción</option>
                                                <option key={0} value={"G"}>Grande</option>
                                                <option key={1} value={"P"}>Pequeña</option>
                                            </select>
                                        </div>                                        
                                    </div>
                                </div>                                

                                <div className="align-middle">
                                    <FaBarcode className="h5" /> 
                                    <label className="ms-2 h4 ">Datos para etiqueta</label>
                                </div>
                                <hr className="t-0 m-0 mb-3" />
                                
                                <div className=" row ">
                                    <div className="d-flex flex-column col col-lg-6 mb-3">
                                        <label>Código de producto (escriba código y pulse enter) *</label>
                                        <input type="number" id="CodigoPr" onKeyUp={keyPressEnter} 
                                            onBlur={()=>{
                                                            if ((formData.CodigoPr !== "") && (formData.descripcion === ""))
                                                                GetInfoProductos(formData.CodigoPr)
                                                        }}  
                                                        onChange={changeText} value={formData.CodigoPr} min={1} 
                                                        required
                                        />
                                    </div>    

                                    <div className="d-flex flex-column col col-lg-6  mb-3">
                                        <label>Unidad de Medida *</label>
                                        <input type="text" id="unidadMedida" disabled={true}  onChange={changeText} value={formData.unidadMedida}/>
                                    </div>  
                                    
                                    <div className="d-flex flex-column col col-lg-6  mb-3">
                                        <label>Descripción etiqueta *</label>
                                        <input type="text" id="descripcion" disabled={true} onChange={changeText} value={formData.descripcion} />
                                    </div>   

                                    <div className="d-flex flex-column col col-lg-6  mb-3">
                                        <label>Cantidad en Etiqueta *</label>
                                        <input type="number" id="cantEtiqueta"  onChange={changeText} value={formData.cantEtiqueta} min={1} required/>
                                    </div>   
                                    
                                    <div className="d-flex flex-column col  col-lg-6  mb-3">
                                        <label>Cantidad de Etiquetas a Imprimir *</label>
                                        <input type="number" id="cantEtiquetaImp"  onChange={changeText} value={formData.cantEtiquetaImp} min={1}  required/>
                                    </div>                                                                 
                                                                    
                                </div>

                                <hr className="t-0 m-0 mb-3" />
                                
                                <div className="text-end">
                                    <Button type="button" style={{backgroundColor:"#00B5B8"}} className="border-0" onClick={hndlBtnImprimir}>
                                        <FaPrint className="h6 m-2"/>Imprimir
                                    </Button>
                                </div>
                            
                            </Accordion.Body>
                        </Accordion.Item>
                    </Accordion>
                </Container> 
                {/*********** seccion de modales **********************/}
                {msgDlgShow && <MsgModalDialogEspecial
                    Show={msgDlgShow}
                    Title={`Impresión de etiquetas`}
                    Icon="x"
                    Message="Importante: los campos con (*) son obligatorios y Cantidad de etiquetas a imprimir debe tener valores superiores a 0. Verifiquelos por favor!!!"
                    BtnOkName="Aceptar"
                    BtnNokName=""
                    HandlerClickOk={()=>setMsgDlgShow(false)}
                    HanlerdClickNok={null}
                />}      
                {/*********** si no selecion impresora **********/}
                {msgShowErrorImp && <MsgDialog
                    Show={msgShowErrorImp}
                    Title={`Imprimir etiquetas`}
                    Icon="x"
                    Message={"Debe seleccionar una impresora"}
                    BtnOkName="Aceptar"
                    BtnNokName=""
                    HandlerClickOk={()=>setMsgShowErrorImp(false)}
                    HanlerdClickNok={null}
                />}                  
                {/*********** cuadro de dialogo para impresion exitosa **********/}
                {msgDlgShowImp && <MsgDialog
                    Show={msgDlgShowImp}
                    Title={`Imprimir etiquetas`}
                    Icon="x"
                    Message={msgResponse}
                    BtnOkName="Aceptar"
                    BtnNokName=""
                    HandlerClickOk={MsgDlghandlerImpOk}
                    HanlerdClickNok={null}
                />}                              
                
                {sHCarga && <LocalMsgModalSpinner 
                    Show={sHCarga}
                    text="Un momento porfavor, procesando..."
                    color="#FF7588"
                /> }                                                  
            </Container>
        </>
    );
}

export default EtiquetaSinRefPage;
