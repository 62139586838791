import { Button } from 'react-bootstrap';
import Modal from 'react-bootstrap/Modal';
import './style.css';
import { useState } from 'react';

const ModalDescripcion = (props: {Show: boolean, Title: string, Icon: string, Descripcion: string, 
                  BtnOkName: string, BtnNokName: string, 
                  HandlerClickOk: any, HanlerdClickNok: any}) => {

    const [desc, setDesc] = useState("");
    const [alert, setAlert] = useState(false);

    const hndlr = (evnt: any) =>{
        setDesc(evnt.target.value);
        setAlert(false);
    }  
    
    const hndlrOk = () =>{
        if (desc.trim() === ""){
            setAlert(true);
        }else{
            props.HandlerClickOk(desc)            
        }
    }      

 return (
      <div>
        <Modal show={props.Show} centered={true} size="lg" >
            <Modal.Header  >
                <Modal.Title className='h3 text-center'>
                    {                        
                        props.Title
                    }
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className='d-flex flex-column'>
                    <label className="m-2 ">Descripción de merma</label>
                    <input type="text" id="desc" className="ms-2  form-control"
                        onChange={hndlr} value={desc}
                    />
                    {alert && <div className=' text-danger'>* La descripción es obligatoria</div>}                
                </div>
            </Modal.Body>                
            <Modal.Footer className=''>
                {
                    (props.BtnOkName) 
                        ? 
                        <Button type="button" className="btn btn-success btnColorOk" onClick={()=>hndlrOk()}>
                            {props.BtnOkName}
                        </Button>
                        : <div></div>
                }      
                {
                    (props.BtnNokName) 
                        ? 
                        <Button type="button" className="btn btn-danger btnColorNOk" onClick={props.HanlerdClickNok}>
                            {props.BtnNokName}
                        </Button>
                        : <div></div>
                }                                      
            </Modal.Footer>
        </Modal>
      </div>
  );
}

export default ModalDescripcion;