import { useEffect, useState } from "react";
import { Accordion, Button, Container,  } from "react-bootstrap";
import { FaCalendarAlt, FaFile, FaLocationArrow, FaRegTrashAlt, FaTruck,  } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import SelectAlmacenes from "../../../components/Almacenes";
import SelectCentro from "../../../components/Centros";
import LocalMsgModalSpinner from "../../../components/LocalMsgModalSpinner";
import * as env from '../../../env';
import { httpApiGetText, httpApiPostText } from "../../../lib";
import DataTable from "react-data-table-component";
import { formatDate, formatTime } from "../../../util";
import MsgDialog from "../../../components/MsgDialog";
import BarraMenuHz from "../../../components/BarraMenoHz";
import { SetEntornoEmp } from "../../../redux/store/Actions";
import MsgMessageDialog from "../../../components/MsgMessageDialog";
import ModalAdd from "./ModalAdd";
import ModalArchivos from "./ModalArchivos";
import ModalDestino from "./ModalDestino";
  
const ConsultaEditaMermasPage = () =>{

    let emp: any = useSelector((state: any) => state.emp);  
    const [hbltBtnAbrir, setHbltBtnAbrir] = useState(true); 
    let [formData, setFormData] = useState({ Pais: "CL", Anno: "", Mes: "",  Centro: "", Almacen: "", Centro_Costo: ""});
    const [sHCarga, setSHCarga] = useState(false);   
    let [showMsgApiResponse, setShowMsgApiResponse] = useState(false); 
    let [showMsgApiResponse2, setShowMsgApiResponse2] = useState(false);     
    const [MsgApiResponse, setMsgApiResponse] = useState<string[]>([]); 
    const [showAdvertencia, setShowAdvertencia] = useState(false);  
    const [showAddSku, setShowAddSku] = useState(false);     
    const [showElimaSku, setShowElimaSku] = useState(false);       
    const [showModalFile, setShowModalFile] = useState(false); 
    const [showModalDestino, setShowModalDestino] = useState(false);                   
    let [msgAdvertencia, setMsgAdvertencia] = useState("");       
    const dispatch = useDispatch();          
    let [Solicitudes, setSolicitudes] = useState<any>([]);    
    let [DetalleMerma, setDetalleMerma] = useState<any>([]);     
    let [estadomerma, setEstadoMerma] = useState(0);        
    let [nroSolicitud, setNroSolicitud] = useState(0);      
    let [itemToErase, setItemToErase] = useState<any>({});           

    let columnas = [
        { name: " Nro. Solicitud", selector: (row:any) => row.NRO_SOLICITUD, sortable: true,  width:"150px", right: true},
        { name: "Sku", selector: (row:any) => row.INV_ITEM_ID, sortable: true,  width:"120px"},
        { name: "Descripción", selector: (row:any) => row.DESCRIPCION, sortable: true,  width:"300px", wrap:true},  
        { name: "Cantidad Adjuntos", selector: (row:any) => row.CANTIDAD_ADJUNTOS, sortable: true,  width:"160px", right: true},      
        { name: "Cantidad", selector: (row:any) => row.CANTIDAD, sortable: true,  width:"120px", right: true},
        { name: "Destino", selector: (row:any) => row.DEST_DESCRIPCION, sortable: true,  width:"120px"},        
        { name: "Fecha", selector: (row:any) => row.FECHA, sortable: true,  width:"180px", wrap:true},        
        { name: "Usuario", selector: (row:any) => row.USUARIO, sortable: true, width:"120px"},         
        { name: "Acciones", selector: (row:any) => row.Acciones, width:"120px"}                          
    ];
    
    const pagOptions = {
        rowsPerPageText: "Filas por páginas",
        rangeSeparatorText: "de",
        selectAllRowsItem: true,
        selectAllRowsItemText: "Todos"
    };       

    const ValidaBtnAbrir = async () => {     
        if ((formData.Anno !== "") &&
            (formData.Centro !== "") && 
            (formData.Almacen !== "")){     
                setHbltBtnAbrir(false); 
                const recurso = `${env.REACT_APP_API_URL}/api/Mermas?Pais=CL&Anno=${formData.Anno}&Mes=${formData.Mes}&Centro=${formData.Centro}&Almacen=${formData.Almacen}`;     
                const response = await httpApiGetText(recurso,{
                    'Content-Type': 'application/json',
                    'Rosen-API-Key': `${env.REACT_APP_Rosen_API_Key}`,
                    'Rosen-API-Secret': `${env.REACT_APP_Rosen_API_Secret}`,
                });
                const arrDta = await JSON.parse(response.message); 
                setSolicitudes(arrDta);
        }else{
            setHbltBtnAbrir(true); 
        }
    }

    const hndlrPeriodo = async (evnt: any) =>{
        const f: Date = evnt.target.value; 
        const yy = f.toString().split('-')[0];
        const mm = f.toString().split('-')[1];

        emp = {...emp, periodoDefault: `${yy}-${mm}`};
        dispatch(SetEntornoEmp({...emp}));  

        sessionStorage.setItem("entorno", JSON.stringify(emp));   
                
        formData = {...formData, Anno: yy, Mes: mm};
        setFormData(formData);
        ValidaBtnAbrir();                    
    }

    const consultaMermas = async () =>{
        const recurso = `${env.REACT_APP_API_URL}/api/Mermas?Pais=CL&Anno=${formData.Anno}&Mes=${formData.Mes}&Centro=${formData.Centro}&Almacen=${formData.Almacen}`;     
        const response = await httpApiGetText(recurso,{
            'Content-Type': 'application/json',
            'Rosen-API-Key': `${env.REACT_APP_Rosen_API_Key}`,
            'Rosen-API-Secret': `${env.REACT_APP_Rosen_API_Secret}`,
        });
        setSHCarga(false);
        const arrDta = await JSON.parse(response.message); 
        if(response.code >= 400){
            console.log("Error Mermas - 400:", response, recurso);
        }else{
            if (arrDta.length > 0){
                setSolicitudes([...arrDta]);
            }else{
                formData = {
                    ...formData, 
                    Centro_Costo: ""
                };                
            }      
            setFormData({...formData});                  
        }        
    }

    const hndlrOpcionCentro = (cnt: string, nal: any) =>{
        
        setSolicitudes([]);
        setDetalleMerma([]);

        if (nal.length === 1){
            formData = {
                ...formData, 
                Centro: cnt, 
                Almacen: nal[0].split("-")[1].trim()
            };
            emp = {...emp, centroDefault: cnt, almacenDefault: nal[0].split("-")[1].trim()}; 
            
            consultaMermas();
        }else{
            formData = {
                ...formData, 
                Centro: cnt, 
                Almacen: ""
            }; 
            emp = {...emp, centroDefault: cnt, almacenDefault: ""};              
        }

        setFormData(formData);   
        dispatch(SetEntornoEmp({...emp})); 
        sessionStorage.setItem("entorno", JSON.stringify(emp));            
    }

    const hndlrOpcionAlmacen = async (evnt: any) =>{

        setSolicitudes([]);

        formData = {...formData, [evnt.target.id]: evnt.target.value.split("-")[0].trim()};
        setFormData(formData);     
        
        emp = {...emp, almacenDefault: evnt.target.value};
        dispatch(SetEntornoEmp({...emp}));  

        sessionStorage.setItem("entorno", JSON.stringify(emp));  
       
        consultaMermas();
    }  
    
    const SelectMerma = async (value: any) =>{

        setNroSolicitud(value);
        setSHCarga(true);
        const recurso = `${env.REACT_APP_API_URL}/api/Mermas?Pais=CL&Anno=${formData.Anno}&Mes=${formData.Mes}&Centro=${formData.Centro}&Almacen=${formData.Almacen}&Solicitud=${value}`;     
        const response = await httpApiGetText(recurso,{
            'Content-Type': 'application/json',
            'Rosen-API-Key': `${env.REACT_APP_Rosen_API_Key}`,
            'Rosen-API-Secret': `${env.REACT_APP_Rosen_API_Secret}`,
        });       
        setSHCarga(false);
        const arrDta = await JSON.parse(response.message);         
        const aux: any = [];
        setEstadoMerma(arrDta.ESTADO_MERMA);
        arrDta.Detalle.map((item: any, index:number) =>{
            const obj = {
                NRO_SOLICITUD: item.NRO_SOLICITUD,
                INV_ITEM_ID: item.INV_ITEM_ID,
                DESCRIPCION: item.DESCRIPCION,
                CANTIDAD: item.CANTIDAD,
                CANTIDAD_ADJUNTOS: item.CANTIDAD_ADJUNTOS,
                DEST_DESCRIPCION: item.DEST_DESCRIPCION,                
                FECHA: item.FECHA,
                USUARIO: item.USUARIO,
                Acciones: <div  className=' d-flex gap-1' >  
                            {
                                <div  className=' d-flex gap-1' >
                                    <a href="#!" id={`${index}`} onClick={()=>{
                                            setItemToErase(item);
                                            setShowModalFile(true);
                                        }}>
                                        <FaFile  title="Gestión anexos" color={'white'}  className='bg-success rounded-circle p-1 h4'/>                                                              
                                    </a> 
                                </div> 
  
                            }                    
                            {
                                (!((arrDta.ESTADO_MERMA === 4) || (arrDta.ESTADO_MERMA === 5)) || ((arrDta.ESTADO_MERMA === 2 && (emp.permisoMermaSupervisor || emp.permisoMermaControlInterno)))) 
                                        ? <div  className=' d-flex gap-1' >
                                            <a href="#!" id={`${index}`} onClick={()=>{
                                                    setItemToErase(item);
                                                    setShowElimaSku(true);
                                                }}>
                                                <FaRegTrashAlt title="Elimina" color={'white'}  className='bg-danger rounded-circle p-1 h4'/>                                                              
                                            </a> 
                                        </div>    
                                        : null
                            }  
                            {
                                ( !((arrDta.ESTADO_MERMA === 4) || (arrDta.ESTADO_MERMA === 5))  || ((arrDta.ESTADO_MERMA === 2)  || emp.permisoMermaControlInterno))
                                ? <div  className='d-flex gap-1'>
                                    <a href="#!" id={`${index}`} onClick={()=>{
                                            setItemToErase(item);
                                            setShowModalDestino(true);
                                        }}>
                                        <FaLocationArrow title="Elimina" color={'white'}  className='bg-primary rounded-circle p-1 h4'/>                                                              
                                    </a> 
                                </div>
                                : null    
                            }                              
                                                           
                        </div>                 
            };

            aux.push({...obj});
        });
        DetalleMerma = [...aux];
        setDetalleMerma(DetalleMerma);      
        console.log(arrDta) ;
    }

    const AddSku = async (sku: any) =>{

        setShowAddSku(false);
        setSHCarga(true);
        const bdy = {
            Pais: "CL",
            Anno: formData.Anno,
            Mes: formData.Mes,
            Centro: formData.Centro,
            Almacen: formData.Almacen,
            NRO_SOLICITUD : nroSolicitud,
            Inv_item_id : sku.sku,   
            CANTIDAD: sku.cantidad,                     
            FECHA : `${formatDate(new Date())}T${formatTime(new Date())}`,
            USUARIO: emp.user,
        };
            
        const recurso = `${env.REACT_APP_API_URL}/api/MermasDetalle`;     
        const response = await httpApiPostText(recurso,"POST", {
            'Content-Type': 'application/json',
            'Rosen-API-Key': `${env.REACT_APP_Rosen_API_Key}`,
            'Rosen-API-Secret': `${env.REACT_APP_Rosen_API_Secret}`,
        }, bdy);
        setSHCarga(false);
        const arrDta = await JSON.parse(response.message);    
        if(response.code >= 400){
            arrDta.DetalleErrores.map((item: any)=>MsgApiResponse.push(`${item.ID} - ${item.MENSAJE_ERROR}`));
            setMsgApiResponse(MsgApiResponse);
            setShowMsgApiResponse(true);
        }else{     
            SelectMerma(nroSolicitud);  
        }                    
    }      
    
    const DeleteSku = async () =>{

        setShowElimaSku(false);
        setSHCarga(true);
        const bdy = {
            Pais: "CL",
            Anno: formData.Anno,
            Mes: formData.Mes,
            Centro: formData.Centro,
            Almacen: formData.Almacen,
            NRO_SOLICITUD : nroSolicitud,
            Inv_item_id : itemToErase.INV_ITEM_ID
        };         
        const recurso = `${env.REACT_APP_API_URL}/api/MermasDetalle`;     
        const response = await httpApiPostText(recurso,"DELETE", {
            'Content-Type': 'application/json',
            'Rosen-API-Key': `${env.REACT_APP_Rosen_API_Key}`,
            'Rosen-API-Secret': `${env.REACT_APP_Rosen_API_Secret}`,
        }, bdy);
        setSHCarga(false);
        const arrDta = await JSON.parse(response.message);   
        if(response.code >= 400){
            setMsgApiResponse(["Se ha presentado un problema al eliminar este SKU para esta Merma"]);
            setShowMsgApiResponse(true);
        }else{   
            SelectMerma(itemToErase.NRO_SOLICITUD); 
        }                    
    } 

    const destino = async (dest: any) =>{
        setShowModalDestino(false);
        setSHCarga(true);
        const bdy = {
            Pais: "CL",
            Anno: itemToErase.ANNO,
            Mes: itemToErase.MES,
            Centro: itemToErase.CENTRO,
            Almacen: itemToErase.ALMACEN,
            NRO_SOLICITUD : itemToErase.NRO_SOLICITUD,
            INV_ITEM_ID : itemToErase.INV_ITEM_ID,   
            DEST_ID: dest
        };
            
        const recurso = `${env.REACT_APP_API_URL}/api/MermaDestino`;     
        const response = await httpApiPostText(recurso,"POST", {
            'Content-Type': 'application/json',
            'Rosen-API-Key': `${env.REACT_APP_Rosen_API_Key}`,
            'Rosen-API-Secret': `${env.REACT_APP_Rosen_API_Secret}`,
        }, bdy);
        setSHCarga(false);
        const arrDta = await JSON.parse(response.message);  
        console.log(arrDta);
        if(response.code >= 400){
            setMsgApiResponse(MsgApiResponse);
            setShowMsgApiResponse2(true);
        }else{     
            setMsgApiResponse(['Actualización exitosa!!!']);
            setShowMsgApiResponse2(true);  
            SelectMerma(nroSolicitud);
        }  
    }
    
    useEffect(()=>{

        const preFiltros = () =>{
            if ((emp.periodoDefault !== "")){
                formData = { ...formData, Anno: emp.periodoDefault.split("-")[0],  Mes: emp.periodoDefault.split("-")[1]};
                setFormData(formData);                                         
            }  
            if ((emp.centroDefault !== "")){
                formData = { ...formData, Centro: emp.centroDefault.split("-")[0].trim()};
                setFormData(formData);                                        
            }    
            if ((emp.almacenDefault !== "")){
                formData = { ...formData, Almacen: emp.almacenDefault.split("-")[0].trim()};
                setFormData(formData);                                        
            }  

            ValidaBtnAbrir(); 
        }

        preFiltros();  
        console.log(emp);       

    }, []);    
     
    return(
        <Container fluid>
            <BarraMenuHz/>  
            <div >
                <div className="text-primary ms-3 mt-3">
                        Home / Mermas / Consulta y edición de Mermas
                </div>
                <div className="h4 mt-4 mb-4 ms-3">CONSULTA Y EDICION DE MERMAS</div>
            </div> 
            <Container fluid>  

                <Accordion  className="mb-4" defaultActiveKey={['0']} alwaysOpen>
                    <Accordion.Item eventKey="0" className="border shadow ">
                        <Accordion.Header><div className=" h5 text-dark">PARÁMETROS MERMAS</div></Accordion.Header>
                        <Accordion.Body>
                            <label className="h6 mb-3">Recuerda completar todos los campos con *</label> 
                            <div className="align-middle">
                                <FaCalendarAlt className="h5" /> 
                                <label className="ms-2 h4 ">Periodo</label>
                            </div>
                            <hr className="t-0 m-0 mb-3" />

                            <div  className="d-flex flex-column col-6">
                                <label className="m-2 ">Mes/Año</label>
                                <input type="month" id="periodo" size={8}  className="ms-2  form-control"
                                    value={`${formData.Anno}-${formData.Mes}`}
                                    onChange={hndlrPeriodo} 
                                    min="1990-01" max="9999-12"
                                />
                            </div>
                            <div className="align-middle mt-4">
                                <FaTruck className="h5" /> 
                                <label className="ms-2 h4 ">Centro y Almacen</label>
                            </div>
                            <hr className="t-0 m-0 mb-3" /> 

                            <div className="row d-flex flex-row mb-3">
                                <Container fluid className="mb-3 col-md-12 col-lg-6" >
                                    <label className="form-label">Seleccionar Centro de distribución</label>    
                                    <div><SelectCentro id="Centro" OnSeleccion={hndlrOpcionCentro}  default={emp.centroDefault} /></div>
                                </Container>
                                <Container fluid className="mb-3  col-md-12 col-lg-6" >
                                    <label className="form-label">Seleccionar Almacen </label>    
                                    <div ><SelectAlmacenes  id="Almacen" centro={formData.Centro}  OnSeleccion={hndlrOpcionAlmacen}   default={emp.almacenDefault} /></div>
                                </Container>                               
                            </div>
                            <div className="row  mb-3">
                                <div className=" col-md-12 col-lg-6" >
                                    <label className="form-label">Solicitudes</label>    
                                    <div>                                
                                        <select  className="form-select" id="sizeTag" aria-label="Default select example" onChange={(evnt: any) => SelectMerma(evnt.target.value)}>
                                            <option value={`0`}>{`Seleccione una solicitud`}</option>    
                                            {Solicitudes.map((item : any, idx:number)=><option key={idx} value={`${item.NRO_SOLICITUD}`}>{`${item.OBSERVACION}`}</option>)}
                                        </select>
                                    </div>
                                </div>                                                                 
                            </div>                            
                        </Accordion.Body>
                    </Accordion.Item>
                </Accordion>  

                <Accordion className="mt-1" defaultActiveKey={['0']} alwaysOpen>
                    <Accordion.Item eventKey="0" className="border shadow ">
                        <Accordion.Header><div className=" h5 text-dark">MERMAS</div></Accordion.Header>
                        <Accordion.Body>
                            {
                                !((estadomerma === 2) || (estadomerma === 4)  || (estadomerma === 5)  ||  (! (estadomerma === 3) && emp.permisoMermaControlInterno))
                                    ? 
                                        <div>
                                            <Button className="btn btn-success" onClick={()=>setShowAddSku(true)}>Agregar nuevos SKU</Button>
                                        </div>
                                    : null
                            }
                            <div className="table-responsive">
                                <DataTable 
                                    columns={columnas}
                                    data={DetalleMerma}
                                    selectableRowsHighlight
                                    fixedHeader
                                    pagination
                                    highlightOnHover
                                    fixedHeaderScrollHeight="600px"
                                    paginationComponentOptions={pagOptions}
                                />                                
                            </div>
                        </Accordion.Body>
                    </Accordion.Item>
                </Accordion> 

            </Container>
            {showMsgApiResponse && <MsgMessageDialog 
                Title="Creación de Mermas"
                Icon={false}
                Show={showMsgApiResponse}
                BtnNokName=""
                BtnOkName="Cerrar"
                Message={MsgApiResponse}
                HandlerdClickOk={()=>{
                    setMsgApiResponse([]);
                    setShowMsgApiResponse(false)}
                }
                HandlerdClickNok={null}
            />} 
            {showMsgApiResponse2 && <MsgMessageDialog 
                Title="Creación de Mermas"
                Icon={true}
                Show={showMsgApiResponse2}
                BtnNokName=""
                BtnOkName="Cerrar"
                Message={MsgApiResponse}
                HandlerdClickOk={()=>{
                    setMsgApiResponse([]);
                    setShowMsgApiResponse2(false)}
                }
                HandlerdClickNok={null}
            />}            
            {showAdvertencia && <MsgDialog 
                Title="Creación de Mermas"
                Icon="!"
                Show={showAdvertencia}
                BtnNokName=""
                BtnOkName="Cerrar"
                Message={msgAdvertencia}
                HandlerClickOk={()=>setShowAdvertencia(false)}
                HanlerdClickNok={null}
            />} 
            {showAddSku && <ModalAdd 
                Title="Ingresa nuevo SKU"
                Icon="!"
                Show={showAddSku}
                BtnNokName="Cancelar"
                BtnOkName="Añadir"
                Message={msgAdvertencia}
                HandlerClickOk={AddSku}
                HanlerdClickNok={()=>setShowAddSku(false)}
            />}
            {showElimaSku && <MsgDialog 
                Title="Elimina SKU"
                Icon="!"
                Show={showElimaSku}
                BtnNokName="Cancelar"
                BtnOkName="Continuar"
                Message="¿Está usted seguro de querer eliminar este SKU?"
                HandlerClickOk={DeleteSku}
                HanlerdClickNok={()=>setShowElimaSku(false)}
            />}            
            {showModalFile && <ModalArchivos 
                Title="Gestión anexos de SKU"
                Icon="!"
                item={itemToErase}
                Show={showModalFile}
                BtnNokName="Cerrar"
                BtnOkName=""
                Message=""
                HandlerClickOk={null}
                HanlerdClickNok={()=>setShowModalFile(false)}
            />}     
            {showModalDestino && <ModalDestino 
                Title="Destino de SKU"
                Icon="!"
                item={itemToErase}
                Show={showModalDestino}
                BtnNokName="Cerrar"
                BtnOkName="Aceptar"
                Message=""
                HandlerClickOk={(dest: any)=>destino(dest)}
                HanlerdClickNok={()=>setShowModalDestino(false)}
            />}               
                      
            {/******* Carga masiva *******************************/}          
            <LocalMsgModalSpinner 
                Show={sHCarga}
                text="Un momento por favor, procesando..."
                color="#FF7588"
            />             
        </Container>    
    );
}

export default ConsultaEditaMermasPage;