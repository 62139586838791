import { Button } from 'react-bootstrap';
import Modal from 'react-bootstrap/Modal';
import { useEffect, useState } from 'react';
import LocalMsgModalSpinner from '../../../../components/LocalMsgModalSpinner';
import { httpApiGetText } from '../../../../lib';
import * as env from '../../../../env';
import { useSelector } from 'react-redux';

const ModalDestino = (props: {Show: boolean, Title: string, Icon: string, Message: string, BtnOkName: string, BtnNokName: string, 
                  HandlerClickOk: any, HanlerdClickNok: any, item: any}) => {

    let emp: any = useSelector((state: any) => state.emp);  
    const [sHCarga, setSHCarga] = useState(false);  
    const [data, setData] = useState<any>([]);  
    const [option, setOption] = useState('');          
   
    const selected = (evnt: any) =>{
        setOption(evnt.target.value);
    }

    const GetDestinosList = async () => {

        setSHCarga(true);
        const recurso = `${env.REACT_APP_API_URL}/api/MermaDestino?Pais=CL`;     
        const response = await httpApiGetText(recurso, {
            'Rosen-API-Key': `${env.REACT_APP_Rosen_API_Key}`,
            'Rosen-API-Secret': `${env.REACT_APP_Rosen_API_Secret}`,
        });
        setSHCarga(false);       
        if (response.code >= 400){
            console.log("Error en el servicio de la Api");
        }else{

            const dt = await JSON.parse(response.message);
            await setData([...dt]);
            setOption(props.item.DEST_ID);
        }
    }

    useEffect(()=>{
        
        GetDestinosList();
        console.log(props.item.DEST_ID);

    }, []);
    
 return (
      <div>
        <Modal show={props.Show} centered={true} size="lg" >
            <Modal.Header  >
                <Modal.Title className='h3 text-center'>
                    {                        
                        props.Title
                    }
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className='row d-flex justify-content-center w-100'>   
                    <select  className="form-select w-50" id="sizeTag" aria-label="Default select example" value={option} onChange={selected}>
                        <option key={-1} value={""}>Seleccione una opción</option>
                        {
                            data.map((dta: any, idx: number)=><option key={idx} value={dta.DEST_ID}>{dta.DEST_DESCRIPCION}</option>)
                        }
                    </select>                                      
                </div>
            </Modal.Body>                
            <Modal.Footer className=''>
                {
                    (props.BtnOkName) 
                        ? 
                        <Button type="button" className="btn btn-success " onClick={()=>props.HandlerClickOk(option)} 

                        >
                            {props.BtnOkName}
                        </Button>
                        : <div></div>
                }      
                {
                    (props.BtnNokName) 
                        ? 
                        <Button type="button" className="btn btn-danger " onClick={props.HanlerdClickNok}>
                            {props.BtnNokName}
                        </Button>
                        : <div></div>
                }                                      
            </Modal.Footer>
        </Modal>    
        {sHCarga && <LocalMsgModalSpinner 
                    Show={sHCarga}
                    text="Un momento porfavor, procesando..."
                    color="#FF7588"
                /> }           
      </div>
  );
}

export default ModalDestino;