import { useEffect, useState } from "react";
import { Accordion, Button, Container } from "react-bootstrap";
import { FaArrowsAlt, FaCalendarAlt, FaClipboardList, FaFacebookMessenger, FaFile, FaHandsHelping, FaHospitalUser, FaRegTimesCircle, FaRegTrashAlt, FaTruck, FaUpload, FaUserCheck, FaUsersCog } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import SelectAlmacenes from "../../../components/Almacenes";
import SelectCentro from "../../../components/Centros";
import LocalMsgModalSpinner from "../../../components/LocalMsgModalSpinner";
import * as env from '../../../env';
import { httpApiDownloadFile, httpApiGetText, httpApiPostText } from "../../../lib";
import DataTable from "react-data-table-component";
import MsgDialog from "../../../components/MsgDialog";
import BarraMenuHz from "../../../components/BarraMenoHz";
import { SetEntornoEmp } from "../../../redux/store/Actions";
import MsgMessageDialog from "../../../components/MsgMessageDialog";
import ModalArchivos from "./ModalArchivos";
import ModalObservacion from "./ModalObservacion";
import MsgDialogObservaciones from "./MsgDialogObservaciones";

  
const GestionMermasPage = () =>{

    let emp: any = useSelector((state: any) => state.emp);  
    const [hbltBtnAbrir, setHbltBtnAbrir] = useState(true); 
    let [formData, setFormData] = useState({ Pais: "CL", Anno: "", Mes: "",  Centro: "", Almacen: "", Centro_Costo: ""});
    const [sHCarga, setSHCarga] = useState(false);   
    let [showMsgApiResponse, setShowMsgApiResponse] = useState(false); 
    let [iconSet, setIconSet] = useState(true);     
    const [MsgApiResponse, setMsgApiResponse] = useState<string[]>([]); 
    const [showAutorizaTienda, setShowAutorizaTienda] = useState(false);     
    const [showAutorizaSupervisor, setShowAutorizaSuperviso] = useState(false);      
    const [showAutorizaControlInterno, setShowAutorizaControlInterno] = useState(false);      
    const [showModalAdjuntos, setShowModalAdjuntos] = useState(false);  
    const [showModalRechaza, setShowModalRechaza] = useState(false);                         
    const dispatch = useDispatch();        
    let [Solicitudes, setSolicitudes] = useState<any>([]);    
    let [DetalleMerma, setDetalleMerma] = useState<any>([]);        
    let [itemSelected, setItemToSelected] = useState<any>({}); 
    let [showModalObs, setShowModalObs] = useState(false);   
    let [textlObs, setTextlObs] = useState('');                

    let columnas = [
        { name: "Centro", selector: (row:any) => row.CENTRO, sortable: true,  width:"90px"},
        { name: "Almacen", selector: (row:any) => row.ALMACEN, sortable: true,  width:"100px"},
        { name: "Id", selector: (row:any) => row.NRO_SOLICITUD, sortable: true,  width:"70px"},
        { name: "Descripción", selector: (row:any) => row.OBSERVACION, sortable: true,  width:"220px", wrap:true},        
        { name: "Centro de costo", selector: (row:any) => row.CENTRO_COSTO, sortable: true, width:"140px", wrap:true},  
        { name: "Estado", selector: (row:any) => row.ESTADO_MERMA, sortable: true, width:"200px", wrap:true}, 
        { name: "Observación", selector: (row:any) => row.OBSERVACION_FINAL, sortable: true,  width:"120px"},              
        { name: "Fecha creación", selector: (row:any) => row.FECHA_CREACION, sortable: true, width:"160px"},    
        { name: "Usuario", selector: (row:any) => row.USU_CREACION, sortable: true, width:"120px"},  
        { name: "Usuario Ult. Actualización", selector: (row:any) => row.ULTIMO_USUARIO, sortable: true, width:"220px"},  
        { name: "Fecha Ult. Actualización", selector: (row:any) => row.ULTIMA_FECHA, sortable: true, width:"220px"},                                                 
        { name: "Acciones", selector: (row:any) => row.Acciones}                          
    ];
    
    const pagOptions = {
        rowsPerPageText: "Filas por páginas",
        rangeSeparatorText: "de",
        selectAllRowsItem: true,
        selectAllRowsItemText: "Todos"
    };       

    const ValidaBtnAbrir = async () => {     
        if ((formData.Anno !== "") &&
            (formData.Centro !== "") && 
            (formData.Almacen !== "")){     
                setHbltBtnAbrir(false); 
                const recurso = `${env.REACT_APP_API_URL}/api/Mermas?Pais=CL&Anno=${formData.Anno}&Mes=${formData.Mes}&Centro=${formData.Centro}&Almacen=${formData.Almacen}`;     
                const response = await httpApiGetText(recurso,{
                    'Content-Type': 'application/json',
                    'Rosen-API-Key': `${env.REACT_APP_Rosen_API_Key}`,
                    'Rosen-API-Secret': `${env.REACT_APP_Rosen_API_Secret}`,
                });
                const arrDta = await JSON.parse(response.message); 
                setSolicitudes(arrDta);
        }else{
            setHbltBtnAbrir(true); 
        }
    }

    const hndlrPeriodo = async (evnt: any) =>{
        const f: Date = evnt.target.value; 
        const yy = f.toString().split('-')[0];
        const mm = f.toString().split('-')[1];

        emp = {...emp, periodoDefault: `${yy}-${mm}`};
        dispatch(SetEntornoEmp({...emp}));  

        sessionStorage.setItem("entorno", JSON.stringify(emp));   
                
        formData = {...formData, Anno: yy, Mes: mm};
        setFormData(formData);
        ValidaBtnAbrir();                    
    }

    const hndlrOpcionCentro = (cnt: string, nal: any) =>{
        
        setSolicitudes([]);

        if (nal.length === 1){
            formData = {
                ...formData, 
                Centro: cnt, 
                Almacen: nal[0].split("-")[1].trim()
            };
            emp = {...emp, centroDefault: cnt, almacenDefault: nal[0].split("-")[1].trim()}; 
            
        }else{
            formData = {
                ...formData, 
                Centro: cnt, 
                Almacen: ""
            }; 
            emp = {...emp, centroDefault: cnt, almacenDefault: ""};              
        }

        setFormData(formData);   
        dispatch(SetEntornoEmp({...emp})); 
        sessionStorage.setItem("entorno", JSON.stringify(emp));            
    }

    const hndlrOpcionAlmacen = async (evnt: any) =>{

        setSolicitudes([]);

        formData = {...formData, [evnt.target.id]: evnt.target.value.split("-")[0].trim()};
        setFormData(formData);     
        
        emp = {...emp, almacenDefault: evnt.target.value};
        dispatch(SetEntornoEmp({...emp}));  

        sessionStorage.setItem("entorno", JSON.stringify(emp));  
    }  
    
    const autorizacionTienda = async (Solicitud: any) =>{

        setSHCarga(true);
        const bdy = {
            Pais: "CL",
            Anno: Solicitud.ANNO,
            Mes: Solicitud.MES,
            Centro: Solicitud.CENTRO,
            Almacen: Solicitud.ALMACEN,
            NRO_SOLICITUD: Solicitud.NRO_SOLICITUD, 
            USU_CREACION: emp.user,
            ESTADO_MERMA: 2            
        };
        const recurso = `${env.REACT_APP_API_URL}/api/MermasAutorizaciones`;     
        const response = await httpApiPostText(recurso,'POST', {
            'Content-Type': 'application/json',
            'Rosen-API-Key': `${env.REACT_APP_Rosen_API_Key}`,
            'Rosen-API-Secret': `${env.REACT_APP_Rosen_API_Secret}`,
        }, bdy);       
        setSHCarga(false);
        const arrDta = await JSON.parse(response.message);
        if(response.code >= 400){
            arrDta.DetalleErrores.map((item: any)=>MsgApiResponse.push(`${item.ID} - ${item.MENSAJE_ERROR}`));
            setMsgApiResponse(MsgApiResponse);
            setShowMsgApiResponse(true);
        }else{
            setMsgApiResponse([arrDta]);
            setShowMsgApiResponse(true);   
            listaMermas();         
        }                   
    }

    const autorizacionSupervisor = async (Solicitud: any) =>{

        setSHCarga(true);
        const bdy = {
            Pais: "CL",
            Anno: Solicitud.ANNO,
            Mes: Solicitud.MES,
            Centro: Solicitud.CENTRO,
            Almacen: Solicitud.ALMACEN,
            NRO_SOLICITUD: Solicitud.NRO_SOLICITUD, 
            USU_CREACION: emp.user,
            ESTADO_MERMA: 3           
        };
        const recurso = `${env.REACT_APP_API_URL}/api/MermasAutorizaciones`;     
        const response = await httpApiPostText(recurso,'POST', {
            'Content-Type': 'application/json',
            'Rosen-API-Key': `${env.REACT_APP_Rosen_API_Key}`,
            'Rosen-API-Secret': `${env.REACT_APP_Rosen_API_Secret}`,
        }, bdy);       
        setSHCarga(false);      
        const arrDta = await JSON.parse(response.message);
        if(response.code >= 400){
            arrDta.DetalleErrores.map((item: any)=>MsgApiResponse.push(`${item.ID} - ${item.MENSAJE_ERROR}`));
            setMsgApiResponse(MsgApiResponse);
            setShowMsgApiResponse(true);
        }else{
            setMsgApiResponse([arrDta]);
            setShowMsgApiResponse(true);  
            listaMermas();                           
        }   

    }    

    const autorizacionControlInterno = async (Solicitud: any) =>{

        setSHCarga(true);
        const bdy = {
            Pais: "CL",
            Anno: Solicitud.ANNO,
            Mes: Solicitud.MES,
            Centro: Solicitud.CENTRO,
            Almacen: Solicitud.ALMACEN,
            NRO_SOLICITUD: Solicitud.NRO_SOLICITUD, 
            USU_CREACION: emp.user,
            ESTADO_MERMA: 4           
        };
        const recurso = `${env.REACT_APP_API_URL}/api/MermasAutorizaciones`;     
        const response = await httpApiPostText(recurso,'POST', {
            'Content-Type': 'application/json',
            'Rosen-API-Key': `${env.REACT_APP_Rosen_API_Key}`,
            'Rosen-API-Secret': `${env.REACT_APP_Rosen_API_Secret}`,
        }, bdy);       
        setSHCarga(false);
        const arrDta = await JSON.parse(response.message);
        if(response.code >= 400){
            arrDta.DetalleErrores.map((item: any)=>MsgApiResponse.push(`${item.ID} - ${item.MENSAJE_ERROR}`));
            setMsgApiResponse(MsgApiResponse);
            setShowMsgApiResponse(true);
        }else{
            setMsgApiResponse([arrDta]);
            setShowMsgApiResponse(true);  
            listaMermas();                           
        }  
    }       
    
    const rechazoMerma = async (Solicitud: any) =>{

        setSHCarga(true);
        const bdy = {
            Pais: "CL",
            Anno: Solicitud.ANNO,
            Mes: Solicitud.MES,
            Centro: Solicitud.CENTRO,
            Almacen: Solicitud.ALMACEN,
            NRO_SOLICITUD: Solicitud.NRO_SOLICITUD,
            OBSERVACION_FINAL: Solicitud.OBSERVACION_FINAL, 
            USU_CREACION: emp.user,
            ESTADO_MERMA: 5           
        };
        const recurso = `${env.REACT_APP_API_URL}/api/MermasAutorizaciones`;     
        const response = await httpApiPostText(recurso,'POST', {
            'Content-Type': 'application/json',
            'Rosen-API-Key': `${env.REACT_APP_Rosen_API_Key}`,
            'Rosen-API-Secret': `${env.REACT_APP_Rosen_API_Secret}`,
        }, bdy);       
        setSHCarga(false);
        const arrDta = await JSON.parse(response.message);
        if(response.code >= 400){
            arrDta.DetalleErrores.map((item: any)=>MsgApiResponse.push(`${item.ID} - ${item.MENSAJE_ERROR}`));
            setMsgApiResponse(MsgApiResponse);
            setShowMsgApiResponse(true);
        }else{
            setMsgApiResponse([arrDta]);
            setShowMsgApiResponse(true);  
            listaMermas();                           
        }  
    } 

    const download = (file: any) => {
        const link = document.createElement('a')
        const url = URL.createObjectURL(file)
      
        link.href = url
        link.download = file.name
        document.body.appendChild(link)
        link.click()
      
        document.body.removeChild(link)
        window.URL.revokeObjectURL(url)
    }

    const descarga = (data: any, fileName: string) =>{
    
        const reader = new FileReader();        
    
        reader.onload = (evt: any) => {
    
            const fl = new File([evt.target.result], fileName, {type: "application/octet-stream"});
            download(fl);
        };      
    
        reader.readAsArrayBuffer(data);
    }    
    
    const descargaArchivo = async (nmFile: string) => {
        setSHCarga(true);
        const recurso = `${env.REACT_APP_API_URL}/api/MermasPdf?Pais=CL&Anno=${itemSelected.ANNO}&Mes=${itemSelected.MES}&Centro=${itemSelected.CENTRO}&Almacen=${itemSelected.ALMACEN}&Solicitud=${itemSelected.NRO_SOLICITUD}`;      
        const response = await httpApiDownloadFile(recurso, {
            'Content-Type': 'application/json',
            'Rosen-API-Key': `${env.REACT_APP_Rosen_API_Key}`,
            'Rosen-API-Secret': `${env.REACT_APP_Rosen_API_Secret}`,
        });
        if (response.code >= 400){  
            setMsgApiResponse(["Se ha presentado un error al momento de descargar el archivo!!!"]);  
            setShowMsgApiResponse(true);
            setIconSet(false);
        }else{
            const data: any = (response.message); 
            descarga(data, nmFile);      
        } 
        setSHCarga(false);
    }      

    const listaMermas = async() =>{

        setSHCarga(true);
        let recurso = "";
        if (formData.Almacen === ""){
            recurso = `${env.REACT_APP_API_URL}/api/MermasAutorizaciones?Pais=CL&Anno=${formData.Anno}&Mes=${formData.Mes}&Centro=&Almacen=`;   
        }else{
            recurso = `${env.REACT_APP_API_URL}/api/MermasAutorizaciones?Pais=CL&Anno=${formData.Anno}&Mes=${formData.Mes}&Centro=${formData.Centro}&Almacen=${formData.Almacen}`;   
        }
        const response = await httpApiGetText(recurso,{
            'Content-Type': 'application/json',
            'Rosen-API-Key': `${env.REACT_APP_Rosen_API_Key}`,
            'Rosen-API-Secret': `${env.REACT_APP_Rosen_API_Secret}`,
        });
        setSHCarga(false);
        const arrDta = await JSON.parse(response.message); 
        console.log(arrDta);
        if(response.code >= 400){
            console.log("Error Mermas - 400:", response, recurso);
            setMsgApiResponse(["Se ha presentado un error al consultar la api de mermas"]);
            setShowMsgApiResponse(true);
        }else{
            const aux: any = [];
            arrDta.map((item: any)=>{
                const obj = {
                    CENTRO: item.CENTRO,
                    ALMACEN: item.ALMACEN,
                    NRO_SOLICITUD: item.NRO_SOLICITUD,
                    CENTRO_COSTO: item.CENTRO_COSTO,
                    OBSERVACION: item.OBSERVACION,
                    FECHA_CREACION: item.FECHA_CREACION,
                    USU_CREACION: item.USU_CREACION,
                    ESTADO_MERMA: item.ESTADO_MERMA_DESC,
                    OBSERVACION_FINAL: item.OBSERVACION_FINAL && <div  className=' d-flex justify-content-center w-100' >
                                            <a href="#!"  onClick={()=>{
                                                    setTextlObs(item.OBSERVACION_FINAL);
                                                    setShowModalObs(true);
                                                }}>
                                                <FaFacebookMessenger title={`${item.OBSERVACION_FINAL}`} color={'black'}  className=' rounded-circle p-1 h4'/>                                                              
                                            </a> 
                                        </div>, 
                    ULTIMO_USUARIO: item.ULTIMO_USUARIO,
                    ULTIMA_FECHA: item.ULTIMA_FECHA,  
                    Acciones: <div  className=' d-flex flex-row gap-3' >                   
                                    {
                                        (true) 
                                                ? <div  className=' d-flex gap-1' >
                                                    <a href="#!" onClick={()=>{
                                                            setItemToSelected(item);
                                                            setShowModalAdjuntos(true);
                                                        }}>
                                                        <FaArrowsAlt  title="Ver adjuntos" color={'white'}  className='bg-warning rounded-circle p-1 h4'/>                                                              
                                                    </a> 
                                                </div>    
                                                : null
                                    }   
                                    {
                                        ((((item.ESTADO_MERMA !== 5) && item.ESTADO_MERMA === 1) )) 
                                                ? <div  className=' d-flex gap-1' >
                                                    <a href="#!"  onClick={()=>{
                                                            setItemToSelected(item);
                                                            setShowAutorizaTienda(true);
                                                        }}>
                                                        <FaHospitalUser title="Autoriza tienda" color={'white'}  className='bg-dark rounded-circle p-1 h4'/>                                                              
                                                    </a> 
                                                </div>    
                                                : null
                                    }  
                                    {
                                        ((item.ESTADO_MERMA !== 5) && (item.ESTADO_MERMA === 2)) 
                                                ? <div  className=' d-flex gap-1' >
                                                    <a href="#!" onClick={()=>{
                                                            setItemToSelected(item);
                                                            setShowAutorizaSuperviso(true);                                                            
                                                        }}>
                                                        <FaUserCheck title="Autoriza supervisor" color={'white'}  className='bg-success rounded-circle p-1 h4'/>                                                              
                                                    </a> 
                                                </div>    
                                                : null
                                    }  
                                    {
                                        ( (item.ESTADO_MERMA !== 5) && (item.ESTADO_MERMA === 3)) 
                                                ? <div  className=' d-flex gap-1' >
                                                    <a href="#!" onClick={()=>{
                                                            setItemToSelected(item);
                                                            setShowAutorizaControlInterno(true); 
                                                        }}>
                                                        <FaUsersCog title="Autoriza C.I." color={'white'}  className='bg-primary rounded-circle p-1 h4'/>                                                              
                                                    </a> 
                                                </div>    
                                                : null
                                    }  
                                    {
                                        ( (item.ESTADO_MERMA !== 5) && (item.ESTADO_MERMA !== 4))  
                                                ? <div  className=' d-flex gap-1' >
                                                    <a href="#!" onClick={()=>{
                                                            setItemToSelected(item);
                                                            setShowModalRechaza(true);
                                                        }}>
                                                        <FaRegTimesCircle  title="Rechazar" color={'white'}  className='bg-danger rounded-circle p-1 h4'/>                                                              
                                                    </a> 
                                                </div>    
                                                : null
                                    }                                       
                                    {
                                        (true)  
                                            ?  <div  className=' d-flex gap-1' >
                                                    <a href="#!" onClick={()=>{
                                                            itemSelected = {...item};
                                                            setItemToSelected(item);
                                                            descargaArchivo(`Merma-${itemSelected.NRO_SOLICITUD}.pdf`)
                                                        }}>
                                                        <FaClipboardList title="PDF Reporte final" color={'white'}  className='bg-secondary rounded-circle p-1 h4'/>                                                              
                                                    </a> 
                                                </div>  
                                            : null  
                                    }                                          
                              </div>         
                };
                aux.push(obj);
            });
            DetalleMerma = [...aux];
            setDetalleMerma(DetalleMerma);
        }  
    }
    
    useEffect(()=>{

        const preFiltros = () =>{
            if ((emp.periodoDefault !== "")){
                formData = { ...formData, Anno: emp.periodoDefault.split("-")[0],  Mes: emp.periodoDefault.split("-")[1]};
                setFormData(formData);                                         
            }  
            if ((emp.centroDefault !== "")){
                formData = { ...formData, Centro: emp.centroDefault.split("-")[0].trim()};
                setFormData(formData);                                        
            }    
            if ((emp.almacenDefault !== "")){
                formData = { ...formData, Almacen: emp.almacenDefault.split("-")[0].trim()};
                setFormData(formData);                                        
            }  

            ValidaBtnAbrir(); 
        }

        preFiltros();   

    }, []);    
     
    return(
        <Container fluid>
            <BarraMenuHz/>  
            <div >
                <div className="text-primary ms-3 mt-3">
                        Home / Mermas / Gestión de Mermas
                </div>
                <div className="h4 mt-4 mb-4 ms-3">GESTIÓN DE MERMAS</div>
            </div> 
            <Container fluid>  

                <Accordion  className="mb-4" defaultActiveKey={['0']} alwaysOpen>
                    <Accordion.Item eventKey="0" className="border shadow ">
                        <Accordion.Header><div className=" h5 text-dark">PARÁMETROS MERMAS</div></Accordion.Header>
                        <Accordion.Body>
                            <label className="h6 mb-3">Recuerda completar todos los campos con *</label> 
                            <div className="align-middle">
                                <FaCalendarAlt className="h5" /> 
                                <label className="ms-2 h4 ">Periodo</label>
                            </div>
                            <hr className="t-0 m-0 mb-3" />

                            <div  className="d-flex flex-column col-6">
                                <label className="m-2 ">Mes/Año</label>
                                <input type="month" id="periodo" size={8}  className="ms-2  form-control"
                                    value={`${formData.Anno}-${formData.Mes}`}
                                    onChange={hndlrPeriodo} 
                                    min="1990-01" max="9999-12"
                                />
                            </div>
                            <div className="align-middle mt-4">
                                <FaTruck className="h5" /> 
                                <label className="ms-2 h4 ">Centro y Almacen</label>
                            </div>
                            <hr className="t-0 m-0 mb-3" /> 

                            <div className="row d-flex flex-row mb-3">
                                <Container fluid className="mb-3 col-md-12 col-lg-6" >
                                    <label className="form-label">Seleccionar Centro de distribución</label>    
                                    <div><SelectCentro id="Centro" OnSeleccion={hndlrOpcionCentro}  default={emp.centroDefault} /></div>
                                </Container>
                                <Container fluid className="mb-3  col-md-12 col-lg-6" >
                                    <label className="form-label">Seleccionar Almacen </label>    
                                    <div ><SelectAlmacenes  id="Almacen" centro={formData.Centro}  OnSeleccion={hndlrOpcionAlmacen}   default={emp.almacenDefault} /></div>
                                </Container>                               
                            </div>
                            <div className="row ">
                                <div className=" col-md-12 col-lg-12 text-end">
                                    <Button type="button" style={{backgroundColor:"#00B5B8"}} className=" btn border border-0" disabled={formData.Anno === ""} onClick={listaMermas}>
                                        Consultar
                                    </Button>
                                </div>                                
                            </div>                         
                        </Accordion.Body>
                    </Accordion.Item>
                </Accordion>  

                <Accordion className="mt-1" defaultActiveKey={['0']} alwaysOpen>
                    <Accordion.Item eventKey="0" className="border shadow ">
                        <Accordion.Header><div className=" h5 text-dark">MERMAS</div></Accordion.Header>
                        <Accordion.Body>
                            <div className="table-responsive">
                                <DataTable 
                                    columns={columnas}
                                    data={DetalleMerma}
                                    selectableRowsHighlight
                                    fixedHeader
                                    pagination
                                    highlightOnHover
                                    fixedHeaderScrollHeight="600px"
                                    paginationComponentOptions={pagOptions}
                                />                                
                            </div>
                        </Accordion.Body>
                    </Accordion.Item>
                </Accordion> 

            </Container>
            {showAutorizaTienda && <MsgDialog 
                Title="Autorización Tienda"
                Icon="!"
                Show={showAutorizaTienda}
                BtnNokName="Cancelar"
                BtnOkName="Continuar"
                Message="¿Está usted seguro de autorizar?"
                HandlerClickOk={()=>{
                    setShowAutorizaTienda(false);
                    autorizacionTienda(itemSelected);
                }}
                HanlerdClickNok={()=>setShowAutorizaTienda(false)}
            />} 
            {showAutorizaSupervisor && <MsgDialog 
                Title="Autorización del Supervisor"
                Icon="!"
                Show={showAutorizaSupervisor}
                BtnNokName="Cancelar"
                BtnOkName="Continuar"
                Message="¿Está usted seguro de autorizar?"
                HandlerClickOk={()=>{
                    setShowAutorizaSuperviso(false);
                    autorizacionSupervisor(itemSelected);
                }}
                HanlerdClickNok={()=>setShowAutorizaSuperviso(false)}
            />}  
            {showAutorizaControlInterno && <MsgDialog 
                Title="Autorización de Control Interno"
                Icon="!"
                Show={showAutorizaControlInterno}
                BtnNokName="Cancelar"
                BtnOkName="Continuar"
                Message="¿Está usted seguro de autorizar?"
                HandlerClickOk={()=>{
                    setShowAutorizaControlInterno(false);
                    autorizacionControlInterno(itemSelected);
                }}
                HanlerdClickNok={()=>setShowAutorizaControlInterno(false)}
            />} 
            {showModalObs && <ModalObservacion 
                Title="Observación"
                Icon="!"
                Show={showModalObs}
                BtnOkName="Continuar"
                Descripcion={textlObs}
                HandlerClickOk={() => {
                                    setShowModalObs(false);
                                } } 
                BtnNokName={""} 
                HanlerdClickNok={undefined}            
            />}            
            {showModalRechaza && <MsgDialogObservaciones 
                Title="Rechazo de Merma"
                Icon="!"
                Show={showModalRechaza}
                BtnNokName="Cancelar"
                BtnOkName="Continuar"
                Message="¿Está usted seguro de rechazar la merma?. De querer hacerlo, escriba una observación:"
                HandlerClickOk={(obs: string)=>{
                    setShowModalRechaza(false);
                    itemSelected.OBSERVACION_FINAL = obs;
                    rechazoMerma(itemSelected);
                }}
                HanlerdClickNok={()=>setShowModalRechaza(false)}
            />}  

            {showModalAdjuntos && <ModalArchivos 
                Title="Gestión anexos de Merma"
                Icon="!"
                item={itemSelected}
                Show={showModalAdjuntos}
                BtnNokName="Cerrar"
                BtnOkName=""
                Message=""
                HandlerClickOk={null}
                HanlerdClickNok={() => setShowModalAdjuntos(false)}
            />}  
            {showMsgApiResponse && <MsgMessageDialog 
                Title="Gestión de Mermas"
                Icon={iconSet}
                Show={showMsgApiResponse}
                BtnNokName=""
                BtnOkName="Cerrar"
                Message={MsgApiResponse}
                HandlerdClickOk={()=>{
                        setMsgApiResponse([]);
                        setShowMsgApiResponse(false);
                        setIconSet(true);
                    }
                }
                HandlerdClickNok={null}
            />}                         

            {/******* Carga masiva *******************************/}          
            <LocalMsgModalSpinner 
                Show={sHCarga}
                text="Un momento por favor, procesando..."
                color="#FF7588"
            />             
        </Container>    
    );
}

export default GestionMermasPage;